import {createAction} from 'typesafe-actions';

import * as Types from './types';

export const setConfig = createAction(
  '@view/panel/setConfig',
  action => (ref: Types.Ref, config: Types.Panel['config'], key?: string) =>
    action({ref, config, key})
);

export const setConfigs = createAction(
  '@view/panel/setConfigs',
  action => (refs: Types.Ref[], configs: Array<Types.Panel['config']>) =>
    action({refs, configs})
);

/**
 * Updates the config of an individual panel.
 */
export const updateConfig = createAction(
  '@view/panel/updateConfig',
  action => (ref: Types.Ref, configUpdate: Partial<Types.Panel['config']>) =>
    action({ref, configUpdate})
);

export const updateConfigs = createAction(
  '@view/panel/updateConfigs',
  action => (refs: Types.Ref[], configUpdate: Partial<Types.Panel['config']>) =>
    action({refs, configUpdate})
);

export type ActionType = ReturnType<typeof setConfig>;
