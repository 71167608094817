import {InputBase} from '@material-ui/core';
import * as globals from '@wandb/weave/common/css/globals.styles';
import {fuzzyMatchWithMapping} from '@wandb/weave/common/util/fuzzyMatch';
import {
  Icon,
  IconAddNew,
  IconFolderAlt,
  IconSearch,
} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {memo, useContext, useState} from 'react';

import {Banner} from '../../../components/Banner';
import {
  useNavContext,
  useNavUpdaterContext,
} from '../../../components/NavContextProvider';
import {AccountSelectorContext} from '../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {AccountType} from '../../../components/Search/SearchNav/types';
import {Skeleton} from '../../../components/Skeleton';
import {
  useHomePageViewerProjectsQuery,
  useViewerUserInfoQuery,
} from '../../../generated/graphql';
import {useViewer} from '../../../state/viewer/hooks';
import {trackAllProjectsClicked} from '../../../util/navigation';
import {useRampFlagAccountSelector} from '../../../util/rampFeatureFlags';
import {allProjects} from '../../../util/urls';
import CreateProjectDrawer from './CreateProjectDrawer';
import * as S from './HomePageSidebar.styles';
import {SidebarItem} from './SidebarItem';
import {SidebarLink} from './SidebarLink';
import {SidebarSectionHeader} from './SidebarSectionHeader';
import {useAccountProjectsList} from './useAccountProjectsList';

const ProjectsListComp = () => {
  const viewer = useViewer();
  const {isCreatingProject} = useNavContext();
  const {toggleCreateProjectDrawer} = useNavUpdaterContext();
  const [searchQuery, setSearchQuery] = useState('');
  const enableAccountSelector = useRampFlagAccountSelector();
  const {accountProjectsList} = useAccountProjectsList(viewer?.id ?? '');
  const {selectedAccount} = useContext(AccountSelectorContext);

  const viewerProjects = useHomePageViewerProjectsQuery({
    variables: {
      entityName: viewer?.username ?? '',
      first: 50,
    },
    skip: enableAccountSelector,
  });

  const data = viewerProjects?.data?.projects ?? {edges: []};
  const projectsList = enableAccountSelector ? accountProjectsList : data;

  const viewerUserInfo = useViewerUserInfoQuery();
  const userInfo = viewerUserInfo?.data?.viewer?.userInfo;
  const shouldHidePersonalEntity = userInfo?.isPersonalEntityHidden ?? false;
  const isPersonalEntitySelectedAccount =
    selectedAccount !== null &&
    selectedAccount.accountType === AccountType.Personal;
  const canNotCreateNewProject =
    shouldHidePersonalEntity && isPersonalEntitySelectedAccount;

  let projects = projectsList.edges.map(n => {
    return {
      searchString: n.node?.name ?? '',
      row: {
        entityName: n.node?.entityName ?? '',
        name: n.node?.name ?? '',
        access: n.node?.access ?? '',
      },
    };
  });

  const haveProjects = projects.length > 0;
  if (searchQuery != null) {
    projects = fuzzyMatchWithMapping(
      projects,
      searchQuery,
      d => d.searchString
    );
  }

  return (
    <S.SidebarList>
      <SidebarSectionHeader headerText="My projects" />
      {viewerProjects.loading && !haveProjects && (
        <>
          {[...Array(4)].map((val, idx) => (
            <Skeleton key={idx} className="mb-6 h-30 rounded opacity-50" />
          ))}
        </>
      )}
      {viewerProjects.error != null && !haveProjects && (
        <Banner
          variant="error"
          eventData={{location: 'home sidebar projects list'}}
          className="px-12 py-8 text-sm">
          We were unable to load your projects.
        </Banner>
      )}

      {haveProjects && (
        <S.ProjectSearchInput button>
          <InputBase
            startAdornment={<IconSearch style={{color: globals.MOON_500}} />}
            placeholder="Search"
            value={searchQuery}
            onChange={e => {
              setSearchQuery(e.target.value);
            }}
          />
        </S.ProjectSearchInput>
      )}

      {projects.slice(0, 10).map((project, idx) => {
        const projectAccess = project.row.access;
        const iconName =
          projectAccess === 'USER_WRITE' || projectAccess === 'USER_READ'
            ? 'lock-open'
            : 'lock-closed';
        const linkText = `${project.row.entityName}/${project.row.name}`;

        return (
          <SidebarLink
            key={`${project.row.name}-${idx}`}
            dataTest="home-sidebar-project-link"
            icon={<Icon name={iconName} />}
            prefixText={project.row.entityName}
            labelText={project.row.name}
            url={`/${linkText}`}
          />
        );
      })}
      {projects.length > 10 && viewer && viewer.username && (
        <SidebarLink
          icon={<IconFolderAlt />}
          labelText="All projects"
          url={allProjects(viewer.username)}
          onClick={() => {
            trackAllProjectsClicked('Home sidebar');
          }}
        />
      )}
      {searchQuery != null &&
        searchQuery !== '' &&
        projects.length === 0 &&
        enableAccountSelector && (
          <Tailwind>
            <div className="rounded-lg bg-moon-50 p-12 text-center text-sm font-semibold text-moon-450">
              No results found. Try searching again or check a different
              account.
            </div>
          </Tailwind>
        )}
      <div>
        <SidebarItem
          icon={<IconAddNew />}
          labelText="Create a new project"
          dataTest="home-create-project-link"
          onClick={toggleCreateProjectDrawer}
          isDisabled={canNotCreateNewProject}
        />
        <CreateProjectDrawer
          open={isCreatingProject}
          toggleProjectDrawer={toggleCreateProjectDrawer}
        />
      </div>
    </S.SidebarList>
  );
};

export const ProjectsList = memo(ProjectsListComp);
