import gql from 'graphql-tag';

export const AVAILABLE_FILES_QUERY = gql`
  query AvailableFilesQuery(
    $projectName: String!
    $entityName: String!
    $runName: String!
    $filenames: [String!]!
    $includeDirectUrl: Boolean!
  ) {
    project(entityName: $entityName, name: $projectName) {
      id
      run(name: $runName) {
        id
        files(names: $filenames) {
          edges {
            node {
              id
              name
              directUrl @include(if: $includeDirectUrl)
              url(upload: false)
              md5
              sizeBytes
            }
          }
        }
      }
    }
  }
`;

export const DIRECT_URL_QUERY = gql`
  query DirectUrlQuery(
    $projectName: String!
    $entityName: String!
    $runName: String!
    $filenames: [String!]!
  ) {
    project(entityName: $entityName, name: $projectName) {
      id
      run(name: $runName) {
        id
        files(names: $filenames) {
          edges {
            node {
              id
              name
              directUrl
              # we include the URL for images as a hack so we can opt out of the blob cache
              url(upload: false)
            }
          }
        }
      }
    }
  }
`;
