import {useMemo} from 'react';

import {Organization} from '../generated/graphql';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {MatchingTeamType} from './JoinMatchingOrgTeams';

export function useMatchedTeams(
  openMatchingOrganizations: Organization[],
  isOnboardingFlow: boolean,
  userTeamIDs: Set<string>
): MatchingTeamType[] {
  const matchingTeams: MatchingTeamType[] = useMemo(() => {
    const matches: MatchingTeamType[] = [];
    openMatchingOrganizations.forEach(({name: orgName, teams}) => {
      teams.forEach(({name, id, memberCount, settings}) => {
        if (
          (isOnboardingFlow || !userTeamIDs.has(id)) &&
          settings.openToMatchingOrgEmailDomainUsers
        ) {
          matches.push({name, orgName, memberCount, id});
        }
      });
    });
    return matches.sort((a, b) => b.memberCount - a.memberCount);
  }, [openMatchingOrganizations, userTeamIDs, isOnboardingFlow]);
  return matchingTeams;
}
