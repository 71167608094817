/**
 * Get the max runs that are defaulted in the panels
 * If a user can use the custom default, return that value
 */
export const DEFAULT_NUM_RUNS_OLD = 10; // legacy setting
/**
 * This was bumped to 30 because want to start increasing the default number
 * of runs in a workspace panel. However, this slows down the RSDQ given that
 * backend parallelism is limited and not likely to be delivered imminently. So
 * we ware bumping this value back to 10 until we can process things more
 * efficiently on the backend.
 *
 * https://weightsandbiases.slack.com/archives/C010Y174QGH/p1714422864169979
 */
export const DEFAULT_NUM_RUNS_NEW = 10;
export const DEFAULT_MAX_NUM_RUNS = 300; // users cannot set max runs higher than this value
export const DEFAULT_MAX_NUM_BUCKETED_RUNS = 50;
export const DEFAULT_MAX_GROUP_RUNS = 100;
