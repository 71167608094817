/**
 * A form multi-line text input.
 */

import {Tailwind} from '@wandb/weave/components/Tailwind';
import classNames from 'classnames';
import React, {TextareaHTMLAttributes} from 'react';

import {TextArea} from './TextArea';

export const FixedSizeTextArea = ({
  className,
  ...props
}: TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  return (
    <Tailwind style={{display: 'contents'}}>
      <TextArea
        style={{height: '80px'}}
        className={classNames(className, 'resize-none')}
        {...props}
      />
    </Tailwind>
  );
};
