import moment from 'moment';

// this file includes utility functions for tracked hour

// calculates the total tracked hour over the list of entities
export function getTotalTrackedHour(
  entities?: Array<{computeHours?: number}>
): moment.Duration | undefined {
  if (entities == null) {
    return undefined;
  }

  let trackedHourInSeconds = 0;

  entities.forEach(entity => {
    trackedHourInSeconds += entity.computeHours ?? 0;
  });

  return getDurationObjectInSeconds(trackedHourInSeconds);
}

export function getTrackedHourUsage(
  teamsPlanTrackedHoursInSecs: number | null,
  teams: Array<{computeHours?: number}>
) {
  // Check if tracked hours for Teams Plan exist
  return teamsPlanTrackedHoursInSecs != null
    ? getDurationObjectInSeconds(teamsPlanTrackedHoursInSecs)
    : getTotalTrackedHour(teams);
}

export function getDurationObjectInSeconds(
  trackedHourInSeconds: number
): moment.Duration {
  return moment.duration(trackedHourInSeconds, 'seconds');
}
