import {Link, TargetBlank} from '@wandb/weave/common/util/links';
import {Button} from '@wandb/weave/components/Button';
import * as DropdownMenu from '@wandb/weave/components/DropdownMenu';
import classNames from 'classnames';
import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router';
import {toast} from 'react-toastify';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Icon, Modal} from 'semantic-ui-react';

import {useDeleteRoleMutation} from '../../../generated/graphql';
import {extractErrorMessageFromApolloError} from '../../../util/errors';
import {
  contactSalesPricing,
  orgRoleEdit,
  orgRolesNew,
} from '../../../util/urls';
import {SettingsCard} from '../../Billing/AccountSettings/SettingsTab/SettingsCard';
import {SettingsHeader} from '../../Billing/AccountSettings/SettingsTab/SettingsHeader';
import {RoleDescription, RoleItem, RoleName} from './CommonRoles';
import {useOrgRolesContext} from './OrgRolesContext';
import {useIsRolesDashboardEnabled} from './useIsRolesDashboardEnabled';
import {CustomRole} from './useOrgRolesContextResult';

const RoleOptions: React.FC<CustomRole> = ({id, inheritedFrom}) => {
  const history = useHistory();
  const {data, refetch} = useOrgRolesContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [deleteRoleMutation] = useDeleteRoleMutation();
  const deleteRole = useCallback(async () => {
    try {
      await deleteRoleMutation({
        variables: {
          roleID: id,
        },
      });
    } catch (err) {
      setIsModalOpen(false);
      const errMsg = extractErrorMessageFromApolloError(err);
      toast(`Error deleting role: ${errMsg}`);
    }
    refetch();
    setIsModalOpen(false);
    toast('Successfully deleted role');
  }, [setIsModalOpen, deleteRoleMutation, id, refetch]);

  return (
    <>
      <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenu.Trigger>
          <Button
            className="hover:bg-moon-100 hover:outline-none"
            variant="ghost"
            startIcon="overflow-horizontal"
            active={isOpen}
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            sideOffset={5}
            alignOffset={-50}
            className="-translate-x-32 rounded border border-moon-250 bg-white shadow-md">
            <DropdownMenu.Item
              onClick={() => history.push(orgRoleEdit(data.orgName, id))}
              className={classNames(
                'cursor-pointer rounded',
                'mx-6 p-6 first:mt-6 last:mb-6',
                '[&_svg]:h-18 [&_svg]:w-18',
                'hover:bg-moon-100 hover:outline-none',
                'radix-disabled:pointer-events-none radix-disabled:text-moon-350'
              )}>
              Edit role
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={() => {
                setIsModalOpen(true);
              }}
              className={classNames(
                'text-red-500',
                'cursor-pointer rounded',
                'mx-6 p-6 first:mt-6 last:mb-6',
                '[&_svg]:h-18 [&_svg]:w-18',
                'hover:bg-moon-100 hover:outline-none',
                'radix-disabled:pointer-events-none radix-disabled:text-moon-350'
              )}>
              Delete role
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      <Modal size="small" open={isModalOpen}>
        <Modal.Header>
          <Icon name="exclamation triangle" />
          Warning
        </Modal.Header>
        <Modal.Content>
          <p>
            Deleting a custom role will result in a <b>downgrade</b> of all
            users and invites that have been assigned this role. When deleted,
            these users will be re-assigned to the predefined{' '}
            <b>{inheritedFrom}</b> role this role inherits from.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="mr-8 hover:bg-moon-100 hover:outline-none"
            variant="secondary"
            onClick={() => {
              setIsModalOpen(false);
            }}>
            Cancel
          </Button>
          <Button variant={'destructive'} onClick={deleteRole}>
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const CustomRoleItem: React.FC<CustomRole> = role => {
  const {name, description} = role;
  return (
    <RoleItem>
      <div className="flex-grow text-left">
        <RoleName>{name}</RoleName>
        <RoleDescription>{description}</RoleDescription>
      </div>
      <RoleOptions {...role} />
    </RoleItem>
  );
};

type CustomRolesProps = {
  orgName: string;
  roles: CustomRole[];
};

const linkStyle = 'text-teal-600 font-semibold hover:text-teal-500';

export const CustomRoles: React.FC<CustomRolesProps> = ({orgName, roles}) => {
  const history = useHistory();
  const isRolesDashboardEnabled = useIsRolesDashboardEnabled();

  const hasNoRoles = !isRolesDashboardEnabled || roles.length === 0;
  let noRolesContent = null;
  if (!isRolesDashboardEnabled) {
    noRolesContent = (
      <>
        <TargetBlank href={contactSalesPricing()} className={linkStyle}>
          Contact sales
        </TargetBlank>{' '}
        to upgrade and access this feature.
      </>
    );
  }
  if (isRolesDashboardEnabled && roles.length === 0) {
    noRolesContent = (
      <>
        <Link to={orgRolesNew(orgName)} className={linkStyle}>
          Create a custom role
        </Link>{' '}
        to get started.
      </>
    );
  }

  return (
    <div>
      <div className="flex items-end justify-between pb-12">
        <SettingsHeader
          title="Custom roles"
          description="Create a role by inheriting a pre-defined role and adding
            permissions to it."
          isPremiumFeatureDisabled={!isRolesDashboardEnabled}
        />
        <Button
          icon="add-new"
          onClick={() => history.push(orgRolesNew(orgName))}
          size="medium"
          variant="secondary"
          disabled={!isRolesDashboardEnabled}>
          Create a role
        </Button>
      </div>

      <SettingsCard>
        {hasNoRoles ? (
          <div className="flex justify-center text-moon-500">
            <div>{noRolesContent}</div>
          </div>
        ) : (
          roles.map(r => (
            <div
              key={r.id}
              className="items-center border-b border-moon-250 py-12 first:pt-0 last:border-transparent last:pb-0">
              <CustomRoleItem {...r} />
            </div>
          ))
        )}
      </SettingsCard>
    </div>
  );
};
