import * as globals from '@wandb/weave/common/css/globals.styles';
import {hexToRGB} from '@wandb/weave/common/css/globals.styles';
import {Link} from '@wandb/weave/common/util/links';
import React, {FC, Fragment, memo, ReactNode, useCallback} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {
  Popup,
  Popup as SemanticPopup,
  StrictPopupProps,
} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled, {css} from 'styled-components';

import {useBetaFeatureNightModeEnabled} from '../../../util/useBetaFeature';

export type NavMenuSection = {
  items: NavMenuItem[];
  header?: ReactNode;
} & MenuSectionProps;

export type NavMenuItem = {
  icon?: ReactNode;
  label?: string;
  content?: ReactNode;
  tooltipText?: string;
  rightSide?: ReactNode;

  to?: string; // Uses our `Link` component which avoids a full page reload
  href?: string; // Uses an `<a>` tag which causes a full page reload

  onClick?: () => void;

  closeMenuOnClick?: boolean;
  disabled?: boolean;
  variant?: string;
  disableHover?: boolean;
};

type NavMenuProps = {
  sections: NavMenuSection[];
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
} & Pick<StrictPopupProps, `trigger`>;

const NavMenuComp: FC<NavMenuProps> = ({
  trigger,
  open,
  onOpen,
  onClose,
  sections,
}) => {
  const isNightMode = useBetaFeatureNightModeEnabled();
  return (
    <PopupMenu
      data-test="nav-profile-menu"
      on="click"
      basic
      position="bottom right"
      trigger={trigger}
      open={open}
      onOpen={onOpen}
      onClose={onClose}>
      <PopupMenuContent>
        {sections.map(({items, header, backgroundColor}, sectionIndex) => (
          <Fragment key={sectionIndex}>
            {sectionIndex > 0 && <MenuDivider isNightMode={isNightMode} />}
            <MenuSection backgroundColor={backgroundColor}>
              {header && <MenuSectionHeader>{header}</MenuSectionHeader>}
              {items.map((item, i) => (
                <MenuItem key={i} item={item} onClose={onClose} />
              ))}
            </MenuSection>
          </Fragment>
        ))}
      </PopupMenuContent>

      {/* HAX: We want the menu to still be affected by the night mode filter,
      so we need a separate element for styles that should not be filtered like box-shadow */}
      <PopupMenuShadow isNightMode={isNightMode} />
    </PopupMenu>
  );
};

const NavMenu = memo(NavMenuComp);

export default NavMenu;

type MenuItemProps = {item: NavMenuItem} & Pick<NavMenuProps, `onClose`>;

const MenuItemComp: FC<MenuItemProps> = ({
  item: {
    icon,
    label,
    content,
    tooltipText,
    rightSide,
    to,
    href,
    onClick,
    closeMenuOnClick = true,
    disabled,
    variant,
    disableHover = false,
  },
  onClose,
}) => {
  const clickHandler = useCallback(() => {
    if (disabled) {
      return;
    }
    onClick?.();
    if (closeMenuOnClick) {
      onClose?.();
    }
  }, [onClick, onClose, closeMenuOnClick, disabled]);

  const contents = content ? (
    content
  ) : (
    <>
      <MenuItemLeft>
        {icon}
        <MenuItemLabel>{label}</MenuItemLabel>
      </MenuItemLeft>
      {rightSide && <MenuItemRight>{rightSide}</MenuItemRight>}
    </>
  );

  const menuItem =
    to && !disabled ? (
      <MenuItemLink onClick={clickHandler} to={to}>
        {contents}
      </MenuItemLink>
    ) : href && !disabled ? (
      <MenuItemA onClick={clickHandler} href={href}>
        {contents}
      </MenuItemA>
    ) : (
      <MenuItemDiv
        onClick={clickHandler}
        disabled={disabled}
        variant={variant}
        disableHover={disableHover}>
        {contents}
      </MenuItemDiv>
    );

  return tooltipText ? (
    <Popup content={tooltipText} position="left center" trigger={menuItem} />
  ) : (
    menuItem
  );
};

const MenuItem = memo(MenuItemComp);

const PopupMenuContent = styled.div`
  max-height: calc(100vh - 2 * ${globals.SEARCH_NAV_HEIGHT});
  overflow: auto;
`;
PopupMenuContent.displayName = 'S.PopupMenuContent';

const PopupMenu = styled(SemanticPopup)`
  &&& {
    padding: 0;
    margin-top: 0;
    background-color: white;
    min-width: 300px;
    max-width: none;
    border: none;
    border-radius: 8px;
  }
`;
PopupMenu.displayName = 'S.PopupMenu';

type PopupMenuShadowProps = {
  isNightMode: boolean;
};

const PopupMenuShadow = styled.div.attrs({
  className: `night-aware`,
})<PopupMenuShadowProps>`
  &&& {
    position: absolute;
    border-radius: 8px;
    box-shadow: ${p =>
      p.isNightMode
        ? globals.MENU_SHADOW_NIGHT_MODE
        : globals.MENU_SHADOW_LIGHT_MODE} !important;

    ${p =>
      p.isNightMode
        ? css`
            top: -1px;
            bottom: -1px;
            left: -1px;
            right: -1px;
            border: 1px solid ${globals.MOON_800};
          `
        : css`
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          `}
  }
`;
PopupMenuShadow.displayName = 'S.PopupMenuShadow';

type MenuSectionProps = {
  backgroundColor?: string;
};

const MenuSection = styled.div<MenuSectionProps>`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 4px 0;

  ${p => p.backgroundColor && `background-color: ${p.backgroundColor};`}
`;
MenuSection.displayName = 'S.MenuSection';

type MenuDividerProps = {
  isNightMode: boolean;
};

const MenuDivider = styled.div.attrs({
  className: `night-aware`,
})<MenuDividerProps>`
  height: 1px;
  background-color: ${p =>
    p.isNightMode ? globals.MOON_800 : globals.MOON_250};
`;
MenuDivider.displayName = 'S.MenuDivider';

const MenuSectionHeader = styled.div`
  margin: 12px 16px 6px;
  font-weight: 600;
`;
MenuSectionHeader.displayName = 'S.MenuSectionHeader';

const VARIANTS = {
  SECONDARY: 'secondary',
  ACCOUNT_SELECTOR: 'account-selector',
};

const menuItemStyles = css<{
  disabled?: boolean;
  variant?: string;
  disableHover?: boolean;
}>`
  &&& {
    margin: 3px 8px;
    padding: 9px 12px;
    border-radius: ${p => (p.variant === VARIANTS.SECONDARY ? '24px' : '4px')};
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s linear;
    color: ${p =>
      p.variant === VARIANTS.SECONDARY
        ? globals.GREEN_600
        : globals.TEXT_PRIMARY_COLOR};
    background-color: ${p =>
      p.variant === VARIANTS.SECONDARY
        ? hexToRGB(globals.GREEN_300, 0.48)
        : p.variant === VARIANTS.ACCOUNT_SELECTOR
        ? hexToRGB(globals.TEAL_550, 0.1)
        : 'auto'};
    font-weight: 400;

    ${p =>
      p.variant === VARIANTS.ACCOUNT_SELECTOR
        ? ``
        : p.disabled
        ? css`
            opacity: 0.5;
          `
        : css`
            cursor: pointer;
            &:hover {
              background-color: ${p.variant === VARIANTS.SECONDARY
                ? hexToRGB(globals.GREEN_300, 0.48)
                : p.disableHover
                ? 'transparent'
                : globals.MOON_150};
            }
          `};
  }
`;

const MenuItemLink = styled(Link)`
  ${menuItemStyles}
`;
MenuItemLink.displayName = 'S.MenuItemLink';

const MenuItemA = styled.a`
  ${menuItemStyles}
`;
MenuItemA.displayName = 'S.MenuItemA';

export const MenuItemDiv = styled.div`
  ${menuItemStyles}
`;
MenuItemDiv.displayName = 'S.MenuItemDiv';

const MenuItemLeft = styled.div`
  display: flex;
  align-items: center;
`;
MenuItemLeft.displayName = 'S.MenuItemLeft';

const MenuItemRight = styled.div`
  display: flex;
  align-items: center;
`;
MenuItemRight.displayName = 'S.MenuItemRight';

const MenuItemLabel = styled.span`
  margin-left: 8px;
`;
MenuItemLabel.displayName = 'S.MenuItemLabel';
