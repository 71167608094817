import gql from 'graphql-tag';

export const ProjectListFragment = gql`
  fragment ProjectList on Project {
    name
    access
    entityName
    totalRuns: runCount
    lastActive
  }
`;
export const PROJECTS_BY_TEAM_QUERY = gql`
  query ProjectsByTeam(
    $entityName: String!
    $pattern: String
    $order: String
    $first: Int = 500
    $before: String
    $after: String
  ) {
    projects(
      first: $first
      entityName: $entityName
      order: $order
      before: $before
      after: $after
      pattern: $pattern
    ) {
      edges {
        node {
          id
          ...ProjectList
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }

  ${ProjectListFragment}
`;

export const PROJECTS_BY_ACCOUNT_QUERY = gql`
  query ProjectsByAccount(
    $orgID: ID!
    $userID: ID!
    $order: String
    $first: Int = 500
    $before: String
    $after: String
    $pattern: String
  ) {
    organization(id: $orgID) {
      id
      projects(
        first: $first
        userID: $userID
        order: $order
        before: $before
        after: $after
        pattern: $pattern
      ) {
        edges {
          node {
            id
            ...ProjectList
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }

  ${ProjectListFragment}
`;

export const PROJECTS_BY_USER_QUERY = gql`
  query ProjectsByUser(
    $userName: String!
    $pattern: String
    $order: String
    $first: Int = 500
    $before: String
    $after: String
  ) {
    user(userName: $userName) {
      id
      username
      projects(
        first: $first
        order: $order
        before: $before
        after: $after
        pattern: $pattern
      ) {
        edges {
          node {
            id
            ...ProjectList
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }

  ${ProjectListFragment}
`;
