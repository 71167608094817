import React, {FC, memo, useCallback, useState} from 'react';

import {maybePluralize} from '../../util/uihelpers';
import * as S from './PurchaseSeatModal.styles';

export interface PurchaseSeatModalProps {
  seatsToPurchase: number;
  pricePerSeat: number;
  billingInterval: string;
  onConfirm: () => Promise<void>;
  onClose(): void;
}

type billingPeriod = 'month' | 'year';

const PurchaseSeatModalComp: FC<PurchaseSeatModalProps> = ({
  seatsToPurchase,
  pricePerSeat,
  billingInterval,
  onConfirm,
  onClose,
}) => {
  // confirmClicked is used to prevent double-clicking the confirm button
  // and to show the pending state.
  const [confirmClicked, setConfirmClicked] = useState(false);

  const handleConfirm = useCallback(async () => {
    setConfirmClicked(true);
    window.analytics?.track('Add New Seat Confirmed', {
      seats: seatsToPurchase,
      location: 'purchase seat modal',
    });
    await onConfirm();
    setConfirmClicked(false);
    onClose();
  }, [onClose, onConfirm, seatsToPurchase]);

  // Show the per-month price for annual plans so that people
  // don't get sticker shock.
  const perMonthSeatPrice =
    (billingInterval as billingPeriod) === 'year'
      ? pricePerSeat / 12
      : pricePerSeat;

  return (
    <S.Modal open size="small" onClose={onClose}>
      <S.Header>Add a new seat?</S.Header>
      <S.Content>
        {`Unlock ${maybePluralize(seatsToPurchase, 'new team seat')} for $${
          seatsToPurchase * perMonthSeatPrice
        }/month in your organization's ${billingInterval}ly plan.`}
      </S.Content>
      {confirmClicked && (
        <S.PendingMessage positive>Adding 1 more seat...</S.PendingMessage>
      )}
      <S.ButtonGroup>
        <S.CancelButton size="small" onClick={onClose}>
          Cancel
        </S.CancelButton>
        <S.InviteButton
          disabled={confirmClicked}
          color="blue"
          size="small"
          data-test="confirm-purchase-seat"
          onClick={handleConfirm}>
          Invite and add {maybePluralize(seatsToPurchase, 'seat')}
        </S.InviteButton>
      </S.ButtonGroup>
    </S.Modal>
  );
};

export const PurchaseSeatModal = memo(PurchaseSeatModalComp);
