import React, {useState} from 'react';
import {toast} from 'react-toastify';

import WBModal from '../../components/WBModal';
import {useUpdateOrganizationMutation} from '../../generated/graphql';
import {
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../../util/errors';
import * as S from './EditOrgName.styles';

type Props = {
  orgName: string;
  orgID: string;
  onSuccess?: () => void;
};

export const EditOrgName = ({orgName, orgID, onSuccess}: Props) => {
  const [updateOrganization] = useUpdateOrganizationMutation({
    context: propagateErrorsContext(),
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState(orgName);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleSubmit = async () => {
    setIsUpdating(true);
    try {
      await updateOrganization({
        variables: {
          organizationID: orgID,
          name,
        },
      });
      toast.success('Organization name updated');
      setIsModalOpen(false);
      onSuccess?.();
    } catch (e) {
      const errorMsg = extractErrorMessageFromApolloError(e) ?? `${e}`;
      console.error(errorMsg);
      toast.error(errorMsg);
    }
    setIsUpdating(false);
  };

  return (
    <>
      <WBModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        trigger={<S.Icon name="edit" onClick={() => setIsModalOpen(true)} />}
        header="Edit Organization Name"
        headerIcon="edit">
        <S.StyledInput
          type="text"
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
        />
        <S.ButtonRow>
          <S.StyledButton onClick={() => setIsModalOpen(false)}>
            Cancel
          </S.StyledButton>
          <S.StyledButton primary onClick={handleSubmit} disabled={isUpdating}>
            Update
          </S.StyledButton>
        </S.ButtonRow>
      </WBModal>
    </>
  );
};
