import {isNotNullUndefinedOrFalse} from '@wandb/weave/common/util/types';
import {clone, setWith} from 'lodash';

export function isOneOf(v: any, values: any[]): boolean {
  return values.indexOf(v) > -1;
}

export function getDomainFromEmail(email: string): string {
  return email.substring(email.lastIndexOf('@') + 1);
}

export function removeNullUndefinedOrFalse<T>(
  arr: Array<T | null | undefined | false>
): T[] {
  return arr.filter(isNotNullUndefinedOrFalse);
}

/* tslint:disable:no-bitwise */
export function cyrb53a(str: string, seed = 0) {
  let h1 = 0xdeadbeef ^ seed;
  let h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 0x85ebca77);
    h2 = Math.imul(h2 ^ ch, 0xc2b2ae3d);
  }
  h1 ^= Math.imul(h1 ^ (h2 >>> 15), 0x735a2d97);
  h2 ^= Math.imul(h2 ^ (h1 >>> 15), 0xcaf649a9);
  h1 ^= h2 >>> 16;
  h2 ^= h1 >>> 16;
  return 2097152 * (h2 >>> 0) + (h1 >>> 11);
}
/* tslint:enable:no-bitwise */

/**
 * Creates a shallow clone of an `obj` through the
 * specified `path` and sets the value at the path to
 * the provided `value`. Useful in reducers where we
 * need to update deeply nested state immutably.
 */
export function setInShallowClone<T extends Record<string, any>>(
  obj: T,
  path: string[],
  value: any
) {
  return setWith(clone(obj), path, value, clone);
}
