import {Maybe} from '../../generated/graphql';
import {Graph} from '../../types/graphql';
import {AccessOptions} from '../../util/permissions';

export const LOCAL_SEARCH_RESULT_TYPES = [`project`, `report`, `team`] as const;
export type LocalSearchResultType = (typeof LOCAL_SEARCH_RESULT_TYPES)[number];

export type LocalSearchResult = {
  type: LocalSearchResultType;
  url: string;
  name: string;
  entityName?: string;
  imageURL?: string;
};

export interface SearchNavProject {
  id: string;
  name: string;
  entityName: string;
  allViews?: Graph<SearchNavReport>;
}

export type SearchNavReport = {
  id: string;
  displayName?: Maybe<string>;
  project?: SearchNavProject;
};

export interface SearchNavTeam {
  id: string;
  name: string;
  photoUrl: string;
  projects: Graph<SearchNavProject>;
}

export interface SearchNavQueryData {
  viewer?: {
    id: string;
    admin: string;
    photoUrl: string;
    username: string;
    teams: Graph<SearchNavTeam>;
    projects: Graph<SearchNavProject>;
    views?: Graph<SearchNavReport>;
  };
  projects?: Graph<SearchNavProject>;
  project?: {
    name: string;
    access: AccessOptions;
    user: {
      id: string;
    };
    isBenchmark: boolean;
    entity: {
      id: string;
      name: string;
      readOnlyAdmin: boolean;
      defaultAccess: AccessOptions;
      privateOnly: boolean;
      isTeam: boolean;
    };
    id: string;
    run?: {
      id: string;
      displayName: string;
      state: string;
    };
    sweep?: {
      id: string;
      name: string;
      displayName?: string;
      config?: string;
    };
  };
}

export type NightAwareProps = {
  isNightMode: boolean;
};
