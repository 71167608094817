import gql from 'graphql-tag';
import {ChildDataProps, graphql} from 'react-apollo';

import * as Generated from '../generated/graphql';
import {User} from '../types/graphql';

// This query returns the currently-logged-in User.
export const GET_VIEWER_QUERY = gql`
  query Viewer($includeStorage: Boolean = false) {
    viewer {
      id
      analyticsId
      admin
      email
      entity
      defaultFramework
      photoUrl
      flags
      code
      username
      createdAt
      name
      accountType
      userInfo
      hideTeamsFromPublic
      limits
      signupRequired
      personalEntityDisabled
      instanceAdminTeamAccessDisabled
      teams(first: 100) {
        edges {
          node {
            id
            name
            photoUrl
            defaultAccess
            readOnlyAdmin
            privateOnly
            isTeam
            organizationId
          }
        }
      }
      organizations {
        id
        name
        orgType
        subscriptions {
          id
          subscriptionType
        }
      }
      defaultEntity {
        id
        name
      }
      userEntity {
        id
        name
        defaultAccess
        codeSavingEnabled
        storageBytes @include(if: $includeStorage)
        settings {
          defaultCloudRegion {
            id
            provider
            region
          }
        }
        isTeam
        defaultAlerts {
          id
          condition {
            __typename
            ... on FinishedRunCondition {
              success
            }
            ... on StoppedRunCondition {
              minimumRunDuration
            }
          }
          subscriptions {
            __typename
            ... on EmailSubscription {
              id
            }
            ... on SlackChannelSubscription {
              id
            }
          }
        }
        integrations {
          edges {
            node {
              id
              __typename
              ... on SlackIntegration {
                teamName
                channelName
              }
            }
          }
        }
        claimedEntities {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_VIEWER_PHOTO_URL_QUERY = gql`
  query ViewerPhotoURL {
    viewer {
      id
      photoUrl
    }
  }
`;

// For benchmarks only; fetches runs recently submitted to benchmark.
export const GET_VIEWER_PROJECTS_QUERY = gql`
  query ViewerProjects($entityName: String, $name: String) {
    viewer {
      id
      entity
      photoUrl
      username
      createdAt
      linkedProjects: projects(
        first: 100
        linkedBenchmark: {EntityName: $entityName, Name: $name}
      ) {
        edges {
          node {
            id
            name
            entityName
            runs(order: "createdAt", first: 50) {
              edges {
                node {
                  id
                  name
                  notes
                  summaryMetrics
                  project {
                    id
                    entityName
                    name
                  }
                  displayName
                  createdAt
                  benchmarkRun {
                    id
                    results
                    originalRun {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      projects(first: 100) {
        edges {
          node {
            id
            name
            entityName
          }
        }
      }
    }
  }
`;

export const VIEWER_FEATURE_FLAGS = gql`
  query viewerFeatureFlags($rampIDType: RampIDType!) {
    viewer {
      id
      featureFlags(rampIDType: $rampIDType) {
        rampKey
        isEnabled
      }
    }
  }
`;

export interface GetViewerQueryResponse {
  viewerLoading: boolean;
  viewer: User;
  refetch: () => void;
}

// Wrap a component with this to access the currently-logged-in user as this.props.viewer
export const withViewer = graphql<
  any,
  GetViewerQueryResponse,
  {},
  ChildDataProps<any, GetViewerQueryResponse, {}>
>(GET_VIEWER_QUERY, {
  options: props => ({
    variables: {
      includeStorage: props.match?.path === '/settings' ?? false, // Include total storage with viewer on settings page
    },
  }),
  props: ({data}) => {
    return {
      viewer: data && data.viewer,
      viewerLoading: data && data.loading,
      refetch: data && data.refetch,
      // viewerError: data && data.error,
    };
  },
});

export interface GetViewerProjectsQueryResponse {
  viewerLoading: boolean;
  viewer: Generated.ViewerProjectsQuery;
  refetch: () => void;
}

export const withViewerProjects = graphql<
  any,
  GetViewerQueryResponse,
  {},
  ChildDataProps<any, GetViewerProjectsQueryResponse, {}>
>(GET_VIEWER_PROJECTS_QUERY, {
  options: props => ({
    variables: {
      entityName: props.match.params.entityName,
      name: props.match.params.projectName,
    },
  }),
  props: ({data, ownProps}) => {
    return {
      viewer: data && data.viewer,
      viewerLoading: data && data.loading,
      // viewerError: data && data.error,
    };
  },
});
