import {WBIcon} from '@wandb/ui';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Button, Input} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const StyledInput = styled(Input)`
  &&& {
    width: 100%;
  }
`;

export const Icon = styled(WBIcon)`
  &&& {
    margin: 10px;
    cursor: pointer;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const StyledButton = styled(Button)`
  &&& {
    width: 100%;
  }
`;
