import React from 'react';

import {PremiumFeatureTooltip} from '../../../../util/accounts/pricing';

type SettingsHeaderProps = {
  title: string;
  description: string;
  isPremiumFeatureDisabled?: boolean;
};

export const SettingsHeader = ({
  title,
  description,
  isPremiumFeatureDisabled,
}: SettingsHeaderProps) => {
  return (
    <div className="mb-12 flex flex-col">
      <div className="flex items-center">
        <h2 className="text-xl font-semibold text-moon-900">{title}</h2>
        {isPremiumFeatureDisabled != null && (
          <PremiumFeatureTooltip
            isPremiumFeatureDisabled={isPremiumFeatureDisabled}
          />
        )}
      </div>
      <p className="text-moon-500">{description}</p>
    </div>
  );
};
