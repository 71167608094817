import {TeamMemberRole} from '../components/MembersTable/TeamMembersTable/utils';
import {useProjectMembersQuery} from '../generated/graphql';
import {useAccountTeamsList} from '../pages/HomePage/HomePageSidebar/useAccountTeamsList';
import {useViewer} from '../state/viewer/hooks';
import {Viewer} from '../state/viewer/types';
import {useAdminModeActive} from './admin';
import {AccessOption} from './permissions';

export interface ViewerHasRestrictedProjectViewParams {
  projectName?: string;
  entityName: string;
  projectAccess: AccessOption;
}

export function useViewerHasRestrictedProjectView({
  projectName,
  entityName,
  projectAccess,
}: ViewerHasRestrictedProjectViewParams): boolean {
  const viewer: Viewer | undefined = useViewer();
  const adminMode = useAdminModeActive();

  const teams = useAccountTeamsList();
  const {data, loading} = useProjectMembersQuery({
    variables: {
      entityName,
      projectName: projectName ?? '',
    },
    skip: projectAccess !== AccessOption.Restricted || projectName == null,
  });

  if (
    loading ||
    projectName == null ||
    adminMode ||
    projectAccess !== AccessOption.Restricted
  ) {
    return false;
  }

  const team = teams.accountTeams.edges.find(t => t.node.name === entityName);
  const viewerMember = team?.node.members.find(t => t.id === viewer?.id);

  const viewerIsTeamAdmin =
    viewerMember?.memberRole.name === TeamMemberRole.ADMIN;
  const viewerIsProjectMember = data?.project?.members?.find(
    m => m.id === viewer?.id
  );

  return viewerIsTeamAdmin && !viewerIsProjectMember;
}
