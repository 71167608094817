// Taken from https://developer.mozilla.org/en-US/docs/Glossary/Base64 and modified slightly
// This is required for b64 payloads that are actually binary.  atob/btoa has issues for
// certain inputs (null chars, other non-printable chars, unicode conversion, etc.)

// Using the Base64 character set definition outlined here: https://datatracker.ietf.org/doc/html/rfc4648
// Also see: https://developer.mozilla.org/en-US/docs/Glossary/Base64
// prettier-ignore
const alphaNumericEnglish = new Set(['1','2','3','4','5','6','7','8','9','0','q','w','e','r','t','y','u','i','o','p','a','s','d','f','g','h','j','k','l','z','x','c','v','b','n','m','Q','W','E','R','T','Y','U','I','O','P','A','S','D','F','G','H','J','K','L','Z','X','C','V','B','N','M', '+', '/']);

/* tslint:disable:no-bitwise */
export function base64DecToArr(sBase64: string): Uint8Array {
  if (typeof sBase64 === 'undefined') {
    return new Uint8Array();
  }

  const sB64Enc = new Array(sBase64.length);
  let cur = 0;
  // tslint:disable
  for (let i = 0; i < sBase64.length; i++) {
    if (alphaNumericEnglish.has(sBase64[i])) {
      sB64Enc[cur] = sBase64[i];
      cur++;
    }
  }
  sB64Enc.length = cur;

  const nInLen = sB64Enc.length;
  const nOutLen = (nInLen * 3 + 1) >> 2;
  const taBytes = new Uint8Array(nOutLen);

  for (
    let nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0;
    nInIdx < nInLen;
    nInIdx++
  ) {
    nMod4 = nInIdx & 3;
    nUint24 |= b64ToUint6(sB64Enc[nInIdx].charCodeAt(0)) << (6 * (3 - nMod4));
    if (nMod4 === 3 || nInLen - nInIdx === 1) {
      for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
        taBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255;
      }
      nUint24 = 0;
    }
  }

  return taBytes;
}

function b64ToUint6(nChr: number): number {
  return nChr > 64 && nChr < 91
    ? nChr - 65
    : nChr > 96 && nChr < 123
    ? nChr - 71
    : nChr > 47 && nChr < 58
    ? nChr + 4
    : nChr === 43
    ? 62
    : nChr === 47
    ? 63
    : 0;
}

function uint6ToB64(nUint6: number): number {
  return nUint6 < 26
    ? nUint6 + 65
    : nUint6 < 52
    ? nUint6 + 71
    : nUint6 < 62
    ? nUint6 - 4
    : nUint6 === 62
    ? 43
    : nUint6 === 63
    ? 47
    : 65;
}

export function base64EncArr(aBytes: Uint8Array): string {
  let nMod3 = 2;
  let sB64Enc = '';

  for (let nLen = aBytes.length, nUint24 = 0, nIdx = 0; nIdx < nLen; nIdx++) {
    nMod3 = nIdx % 3;
    if (nIdx > 0 && ((nIdx * 4) / 3) % 76 === 0) {
      sB64Enc += '\r\n';
    }
    nUint24 |= aBytes[nIdx] << ((16 >>> nMod3) & 24);
    if (nMod3 === 2 || aBytes.length - nIdx === 1) {
      sB64Enc += String.fromCodePoint(
        uint6ToB64((nUint24 >>> 18) & 63),
        uint6ToB64((nUint24 >>> 12) & 63),
        uint6ToB64((nUint24 >>> 6) & 63),
        uint6ToB64(nUint24 & 63)
      );
      nUint24 = 0;
    }
  }

  return (
    sB64Enc.substr(0, sB64Enc.length - 2 + nMod3) +
    (nMod3 === 2 ? '' : nMod3 === 1 ? '=' : '==')
  );
}

/* tslint:enable:no-bitwise */
