import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {FC, useContext, useState} from 'react';
import {useLocation} from 'react-router';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Icon} from 'semantic-ui-react';

import {
  getCookie,
  getCookieBool,
  setCookie,
} from '../../../../../../services/weave-python/weave-public/weave-js/src/common/util/cookie';
import {Organization} from '../../../generated/graphql';
import {AccountSettingsUpdaterContext} from '../../../pages/Billing/AccountSettings/AccountSettingsContextProvider';
import {TeamsPlanCheckoutModal} from '../../../pages/Billing/Checkout/TeamsPlanCheckoutModal';
import {
  getRouteMatch,
  isBillingPath,
  isUsagePath,
  isUserSettingsPath,
} from '../../../routes';
import {Analytics} from '../../../services/analytics';
import {StripeElementsComp} from '../../../util/accounts/pricing';
import {AggressiveRedirectEnforcement} from '../../AggressiveRedirectEnforcement';
import {
  Banner,
  BannerBoldText,
  BannerFlexWrapper,
  BannerTextWrapper,
} from '../../Banner';
import {
  GlobalBannerUpdaterContext,
  useGetFilteredGlobalBannerStatesForSingleOrg,
  ViewerOrg,
} from '..';

export type TrackedHourLimitExceededWarningBannerProps = {
  organization?: Organization | ViewerOrg;
  currentPlan?: string;
  viewerCanUpgrade?: boolean;
};

export const TrackedHourLimitExceededWarningBanner: FC = () => {
  const state = useGetFilteredGlobalBannerStatesForSingleOrg();
  const location = useLocation();

  if (
    state == null ||
    state.account == null ||
    state.freeAccountEnforcementBannerFlags == null ||
    state.freeAccountEnforcementBannerFlags
      .trackedHourLimitExceededWarningBannerProps == null
  ) {
    return null;
  }

  const onUsagePage = isUsagePath(location.pathname);
  const onBillingPage = isBillingPath(location.pathname);
  const onUserSettingsPage = isUserSettingsPath(location.pathname);
  const routeMatch = getRouteMatch(location.pathname);
  const params = routeMatch?.params as {entityName: string} | undefined;
  const entityName = params?.entityName;

  const seenBannerKey = 'seenTrackedHourLimitExceededWarningBanner';
  const seenBanner = getCookieBool(seenBannerKey);

  if (!seenBanner) {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 90);
    setCookie(seenBannerKey, Date.now().toString(), expireDate);
  }

  const cookie = getCookie(seenBannerKey);

  const firstViewTimestamp = cookie !== '' ? parseInt(cookie, 10) : 0;

  const daysElapsedSinceSeenBanner = Math.floor(
    (Date.now() - firstViewTimestamp) / (1000 * 60 * 60 * 24)
  );

  const onAllowedPage = onBillingPage || onUserSettingsPage || onUsagePage;
  const shouldRedirectToBillingPage =
    daysElapsedSinceSeenBanner > 14 && !onAllowedPage;

  if (shouldRedirectToBillingPage) {
    return (
      <AggressiveRedirectEnforcement
        analyticsLocation="exceeded free tracked hour limit banner"
        entityName={entityName}
        organizationName={state.account.name}
      />
    );
  }

  return (
    <Tailwind>
      <div className="mb-8 space-y-2">
        <TrackedHourLimitExceededWarningBannerComp
          {...state.freeAccountEnforcementBannerFlags
            .trackedHourLimitExceededWarningBannerProps}
        />
      </div>
    </Tailwind>
  );
};

const TrackedHourLimitExceededWarningBannerComp: FC<
  TrackedHourLimitExceededWarningBannerProps
> = ({organization, currentPlan, viewerCanUpgrade}) => {
  const [openModal, setOpenModal] = useState(false);

  const globalBannerUpdater = useContext(GlobalBannerUpdaterContext);
  const accountSettingsUpdater = useContext(AccountSettingsUpdaterContext);

  if (organization == null || currentPlan == null) {
    return null;
  }

  return (
    <>
      <Banner
        variant="error"
        data-test="tracked-hour-limit-exceeded-banner"
        eventData={{
          location: 'tracked hour limit exceeding warning banner',
          organizationName: organization.name,
          currentPlan,
          isUserAdminOrBilling: viewerCanUpgrade,
        }}>
        <BannerFlexWrapper>
          <Icon name="warning" />
          <BannerTextWrapper>
            <BannerBoldText>
              You have exceeded 100% of your tracking hours.
            </BannerBoldText>
            {viewerCanUpgrade
              ? 'Please upgrade your plan to continue using W&B without disruption.'
              : `Please contact an admin or billing user on ${organization.name} in order to upgrade and continue using W&B without disruption.`}
          </BannerTextWrapper>
        </BannerFlexWrapper>
        {viewerCanUpgrade && (
          <div
            className="cursor-pointer font-bold"
            onClick={() => {
              setOpenModal(true);
              Analytics.track('Upgrade Now Button Clicked', {
                location: 'tracked hour limit exceeding warning banner',
                organizationName: organization.name,
                currentPlan,
              });
            }}>
            Upgrade Now
          </div>
        )}
      </Banner>
      {openModal && viewerCanUpgrade && (
        <StripeElementsComp>
          <TeamsPlanCheckoutModal
            org={organization}
            onTransactionCompleted={() => {
              setOpenModal(false);
              globalBannerUpdater.refetch();
              accountSettingsUpdater.refetch?.();
            }}
            onClose={() => {
              setOpenModal(false);
            }}
          />
        </StripeElementsComp>
      )}
    </>
  );
};
