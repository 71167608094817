import {AccountType} from '../../../components/Search/SearchNav/types';
import {
  CardBrand,
  Organization,
  OrganizationMember,
  OrganizationSubscriptionType,
} from '../../../generated/graphql';
import {Viewer} from '../../../state/viewer/types';
import {
  getPrimarySub,
  HasSubsWithPlanType,
} from '../../../util/accounts/pricing';
import {OrganizationMemberRole} from '../EditOrgMembersModal';

export const ALLOWED_EXPANSION_EMAILS = [
  'haruka@wandb.com',
  'joe.crawley@wandb.com',
  'seph.mard@wandb.com',
  'aditya.choudhari@wandb.com',
  'henry.huang@wandb.com',
];

export function getIsViewerBillingUser(
  viewer?: Viewer,
  organization?: Organization
): boolean {
  if (viewer == null || organization == null) {
    return false;
  }

  return viewer.username === organization.billingUser?.username;
}

export enum PaymentMethodTagType {
  PRIMARY = 'primary',
  PAYMENT_FAILED = 'payment-failed',
}

type CardDisplayInfo = {
  displayName: string;
  logoName: string;
};

export const CardBrands: Record<CardBrand, CardDisplayInfo> = {
  [CardBrand.Amex]: {displayName: 'American Express', logoName: 'logo-amex'},

  [CardBrand.Diners]: {displayName: 'Diners Club', logoName: 'logo-diners'},
  [CardBrand.Discover]: {displayName: 'Discover', logoName: 'logo-discover'},
  [CardBrand.Interac]: {displayName: 'Interac', logoName: 'logo-interac'},
  [CardBrand.Jcb]: {displayName: 'JCB', logoName: 'logo-jcb'},
  [CardBrand.Mastercard]: {
    displayName: 'Mastercard',
    logoName: 'logo-mastercard',
  },
  [CardBrand.Unionpay]: {displayName: 'UnionPay', logoName: 'logo-unionpay'},
  [CardBrand.Visa]: {displayName: 'Visa', logoName: 'logo-visa'},
  [CardBrand.Unknown]: {displayName: 'Unknown', logoName: 'logo-unknown'},
};

export function getViewerOrganizationMemberRole(
  viewer?: Viewer,
  members?: Array<Pick<OrganizationMember, 'username' | 'id' | 'role'>>
): OrganizationMemberRole | undefined {
  if (viewer == null || members == null) {
    return undefined;
  }

  const viewerMember = members.find(
    member => member.username === viewer.username
  );

  return getOrganizationMemberRole(viewerMember?.role ?? '');
}

export function getOrganizationMemberRole(
  role: string
): OrganizationMemberRole | undefined {
  const roleList = [
    OrganizationMemberRole.ADMIN,
    OrganizationMemberRole.BILLING_ONLY,
    OrganizationMemberRole.MEMBER,
    OrganizationMemberRole.VIEW_ONLY,
  ];

  const matchedRole = roleList.find(r => role === r);
  return matchedRole;
}

export function getUsagePercent(usage: number, limit = 0): number | null {
  if (limit === 0) {
    return null;
  }

  return Math.min((usage / limit) * 100, 100);
}

type HasSubsWithSubscriptionType = {
  subscriptions?: Array<{subscriptionType?: OrganizationSubscriptionType}>;
};
type HasAccountType = {
  accountType?: AccountType;
};

export function getAccountType<
  T extends HasSubsWithSubscriptionType & HasAccountType & HasSubsWithPlanType
>(organization: T): AccountType {
  if (organization.accountType === AccountType.Personal) {
    return AccountType.Personal;
  }

  const primarySub = getPrimarySub(organization);
  if (primarySub == null) {
    // this is a weird situation but only personal account could have no primary subscription
    // so if we don't have a primary subscription, we can assume this is a personal account
    return AccountType.Personal;
  }

  if (
    primarySub.subscriptionType === OrganizationSubscriptionType.Academic ||
    primarySub.subscriptionType === OrganizationSubscriptionType.AcademicTrial
  ) {
    return AccountType.Academic;
  }

  return AccountType.Corporate;
}
