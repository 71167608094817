import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import * as globals from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Modal,
  Table,
} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const Flags = styled.div`
  margin-bottom: 32px;
`;

export const FlagText = styled.div`
  margin-bottom: 12px;
`;

export const Flag = styled.div`
  display: flex;
  align-items: center;
`;

export const FlagCheckbox = styled(Checkbox)`
  margin-right: 8px;
`;

export const EditOrgMembersModalWrapper = styled(Modal)`
  .edit-user-modal {
    width: 800px !important;
  }
`;

export const EditOrgMembersModalTable = styled(Table)`
  margin-bottom: 0 !important;
`;

export const EditOrgMembersModalTableCell = styled(Table.Cell)`
  flex-grow: 0;
`;

export const EditOrgMembersModalTableHeaderCell = styled(Table.HeaderCell)`
  font-weight: 600 !important;
  font-size: 14px !important;
  padding-bottom: 4px !important;
`;

export const EditOrgMembersModalDivider = styled(Divider)`
  margin-top: 0 !important;
`;

export const EditOrgMembersModalAddUserForm = styled.form`
  display: flex;
  flex-wrap: nowrap;
  margin: 10px 0;
  flex-direction: column;
`;

export const EditOrgMembersModalAddUserFormDropdown = styled(Dropdown)`
  margin: 0 1em;
`;

export const EditOrgMembersModalRoleDropdown = styled(Dropdown)`
  margin: 0 0em;
`;

export const EditOrgMembersModalIcon = styled(LegacyWBIcon)`
  cursor: pointer;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const EditOrgMembersModalAddUserHeader = styled.div`
  font-family: ${globals.fontName};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0px;
`;

export const EditOrgMembersModalAddUserHeaderContainer = styled.div`
  margin-bottom: 16px;
`;
export const EditOrgMembersModalAddUserFormField = styled(Form.Field)`
  width: 100%;
  margin: 0 12px 0 0;

  label {
    font-family: ${globals.fontName};
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  [role='combobox'] {
    margin-left: 0px !important;
  }

  .ui.input {
    width: 100% !important;
  }
`;

export const EditOrgMembersModalAddUserButton = styled(Button)`
  align-self: flex-end;
`;

export const EditOrgMembersModalDeploymentFormField = styled(Form.Field)`
  width: 100%;
  margin: 0 12px 0 0;

  label {
    font-family: ${globals.fontName};
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding-right: 12px;
    display: block;
    width: 200px;
  }

  [role='combobox'] {
    margin-left: 0px !important;
  }

  .ui.input {
    width: 60% !important;
  }
`;
