import {notEmpty} from '@wandb/weave/common/util/obj';
import _ from 'lodash';

import {HistorySpec, RunHistoryRow} from '../../types/run';
import * as Update from '../../util/update';
import {NormalizedSampledHistory} from './types';

type NormalizedSampledHistoryUpdate = NormalizedSampledHistory[number];

// Does an immutable update to history, given historySpecs (the query) and
// historyResult (the result of that query).
//
// Since runs are normalized, but different queries typically request different
// parts of history, we store all the history spec along side the result for each
// history spec that we have. Selector code uses getQueryHistoryResults below
// to reverse this.
export function updateRunHistory(
  history: NormalizedSampledHistory,
  queryResult: NormalizedSampledHistory,
  sourceHistorySpecs: HistorySpec[] // the history specs we're actually going to update, which are subsets of historySpecs
): NormalizedSampledHistory {
  let result = history;

  // sourceHistorySpecs are the historySpecs for the source queries, that
  // resulted in the query for which we're handling queryResult. We store
  // normalizedHistory in terms of source queries, not merged queries. So
  // we put the current queryResult rows at each sourceHistorySpec that
  // is a subset of the current queryResult spec. In other words, we
  // unmerge the history results here.
  const updates: NormalizedSampledHistoryUpdate[] = queryResult.flatMap(
    ({spec: querySpec, rows: queryRows}) =>
      sourceHistorySpecs
        .filter(spec => specIsSubset(querySpec, spec))
        .map(spec => {
          const rows: RunHistoryRow[] = queryRows.map(r =>
            _.pick(r, spec.keys)
          );
          return {spec, rows};
        })
  );

  const updatesDedupedBySpec = mergeUpdatesForSameSpec(updates);

  for (const {spec, rows} of updatesDedupedBySpec) {
    const historyItemIndex = findHistoryItemIndex(result, spec);
    if (historyItemIndex === -1) {
      result = [...result, {spec, rows}];
    } else {
      const historyItem = result[historyItemIndex];
      const prevRows = historyItem.rows;
      if (shouldUpdateHistoryItem(prevRows, rows)) {
        result = Update.updateArrayIndex(result, historyItemIndex, {
          ...historyItem,
          rows,
        });
      }
    }
  }

  return result;
}

// We store normalized histories for the run (for all the queries we have
// that have asked for history for this run). Here we map back to just
// the result for the specific query that we're considering.
export function getQueryHistoryResults(
  history: NormalizedSampledHistory,
  historySpecs: HistorySpec[]
) {
  return historySpecs
    .map(spec => history[findHistoryItemIndex(history, spec)])
    .filter(notEmpty)
    .map(hi => hi.rows);
}

function specIsSubset(spec: HistorySpec, testSpec: HistorySpec) {
  return (
    spec.samples === testSpec.samples &&
    spec.minStep === testSpec.minStep &&
    spec.maxStep === testSpec.maxStep &&
    testSpec.keys.length === _.intersection(spec.keys, testSpec.keys).length
  );
}

function findHistoryItemIndex(
  history: NormalizedSampledHistory,
  spec: HistorySpec
): number {
  return _.findIndex(history, hi => _.isEqual(hi.spec, spec));
}

function mergeUpdatesForSameSpec(
  updates: NormalizedSampledHistoryUpdate[]
): NormalizedSampledHistoryUpdate[] {
  const updatesBySpecKey = _.groupBy(updates, ({spec}) => getKeyForSpec(spec));
  return Object.values(updatesBySpecKey).map(updatesForSpec =>
    pickBestUpdate(updatesForSpec)
  );
}

function getKeyForSpec(spec: HistorySpec): string {
  // Object property order is not guaranteed in JavaScript.
  // See: https://stackoverflow.com/a/5525820
  // So we transform objects into entries, sort the entries by key, then stringify.
  // This guarantees that objects with the same key/value pairs will stringify into the exact same string.
  return JSON.stringify(transformObjectIntoSortedEntriesRecursively(spec));
}

function transformObjectIntoSortedEntriesRecursively(x: any): any {
  if (x == null || typeof x !== 'object') {
    return x;
  }
  if (Array.isArray(x)) {
    return x.map(transformObjectIntoSortedEntriesRecursively);
  }
  return _.sortBy(Object.entries(x), ([k]) => k).map(([k, v]) => [
    k,
    transformObjectIntoSortedEntriesRecursively(v),
  ]);
}

function pickBestUpdate(
  updates: NormalizedSampledHistoryUpdate[]
): NormalizedSampledHistoryUpdate {
  // For now, we just pick the update with the most rows.
  return _.maxBy(updates, ({rows}) => rows.length)!;
}

function shouldUpdateHistoryItem(
  prevRows: RunHistoryRow[],
  newRows: RunHistoryRow[]
): boolean {
  if (prevRows.length !== newRows.length) {
    return true;
  }
  if (newRows.length === 0) {
    return false;
  }

  const prevLastStep = _.last(prevRows)?._step;
  const curLastStep = _.last(newRows)?._step;
  if (prevLastStep == null || curLastStep == null) {
    // We don't have last step for both, so we just update.
    return true;
  }

  // We do have a last step for both, so we only update if they differ
  // Note this attempt to update less frequently won't be very effect with
  // non-deterministic sampling.
  return prevLastStep !== curLastStep;
}
