import React, {FC, memo} from 'react';

import * as URL from '../../../util/urls';
import {useNavContext} from '../../NavContextProvider';
import {useIsGlobalRegistryEnabled} from '../../Registries/common/hooks';
import NavBreadcrumbs from './NavBreadcrumbs';
import {NavBreadcrumbsRegistries} from './NavBreadcrumbs/NavBreadcrumbsRegistries';

const NavLeftDefaultComp: FC = () => {
  const {params, shouldShowBreadcrumbs} = useNavContext();
  const {enableGlobalRegistry} = useIsGlobalRegistryEnabled();
  if (
    params.entityName == null &&
    URL.isOnRegistriesPage() &&
    enableGlobalRegistry
  ) {
    return <NavBreadcrumbsRegistries registryName={params.registryName} />;
  }
  if (params.entityName == null || !shouldShowBreadcrumbs) {
    return null;
  }

  return <NavBreadcrumbs />;
};

const NavLeftDefault = memo(NavLeftDefaultComp);

export default NavLeftDefault;
