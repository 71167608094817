import fnv from 'fnv-plus';

import {logHandledError} from '../../services/errors/errorReporting';
import {startPerfTimer} from '../profiler';
import {
  checkRampFlagEnableOPFSCache,
  useRampFlagEnableOPFSCache,
} from '../rampFeatureFlags';

function getEntityNameFromPath(): string {
  return window.location.pathname.split('/')[1];
}
export const opfsEnabled = async (entityName?: string): Promise<boolean> => {
  const teamName = entityName || getEntityNameFromPath();

  return await checkRampFlagEnableOPFSCache(teamName);
};

export const useOpfsEnabled = () => {
  return useRampFlagEnableOPFSCache(getEntityNameFromPath());
};

export async function fetchFromCache<T>(fileName: string): Promise<T | null> {
  if (!(await opfsEnabled())) {
    console.warn(
      'fetchFromCache disabled for user, but fetchFromCache still called',
      window.viewer?.username
    );
    return null;
  }
  return logPerfAndErrors(`fetchFromCache`, async () => {
    const jsonStr = await getOPFSFileTextContent(fileName);
    return JSON.parse(jsonStr);
  });
}

export async function checkFileExistsInOPFS(
  fileName: string
): Promise<boolean> {
  if (!(await opfsEnabled())) {
    console.warn(
      'checkFileExistsInOPFS disabled for user',
      window.viewer?.username
    );
    return false;
  }
  try {
    const root = await navigator.storage.getDirectory();
    await root.getFileHandle(fileName);
    return true;
  } catch (err) {
    return false;
  }
}

export async function storeInCache<T>(
  fileName: string,
  data: T
): Promise<void> {
  return logPerfAndErrors(`storeInCache`, async () => {
    if (data === undefined) {
      throw new Error(`data cannot be undefined (must be JSON-serializable)`);
    }
    const jsonStr = JSON.stringify(data);
    await storeTextFileInOPFS(fileName, jsonStr);
  });
}

async function logPerfAndErrors<T>(
  operationName: string,
  fn: () => Promise<T>
): Promise<T | void> {
  const {endPerfTimer} = startPerfTimer(operationName);
  try {
    const result = await fn();
    return result;
  } catch (err: any) {
    // Ignore expected errors
    if (shouldIgnoreError(err)) {
      return;
    }

    logHandledError(`[OPFS: ${operationName}] Error - ${err}`);
  } finally {
    endPerfTimer();
  }
}

function shouldIgnoreError(err: any): boolean {
  // The user is a dirty hoarder and has no space left for us :'(
  // Or maybe we're the ones who stored too much data :P
  if (errNameIs(err, `QuotaExceededError`)) {
    return true;
  }

  // Ignore errors that occur when the browser doesn't have adequate OPFS support
  if (
    errNameIs(err, `TypeError`) &&
    (errMsgContains(err, `is not a function`) ||
      errMsgContains(err, `navigator.storage`))
  ) {
    return true;
  }

  // Firefox doesn't allow usage of OPFS in private (incognito) mode.
  // There may be other examples of cases where the browser is configured
  // to not allow OPFS usage.
  if (errNameIs(err, `SecurityError`)) {
    return true;
  }

  // Ignore errors that occur when we cannot obtain a lock on the file.
  // This can happen when multiple callers are trying to store a value under the same key.
  // I don't believe it has any negative impact on the user experience, since
  // parallel callers should be writing identical values.
  // TODO: Verify this assumption and maybe prevent simultaneous write attempts
  if (errNameIs(err, `NoModificationAllowedError`)) {
    return true;
  }

  // Transient internal errors. To my knowledge, these are not directly caused by app code.
  // They're probably exacerbated by overly eager access patterns, though.
  if (errNameIs(err, `InvalidStateError`)) {
    return true;
  }

  // Ignore errors that occur when the file doesn't exist
  if (isNotFoundError(err)) {
    return true;
  }

  // Ignore JSON parse errors
  if (errMsgContains(err, `json parse error`)) {
    return true;
  }

  return false;
}

function isNotFoundError(err: any): boolean {
  // Chrome
  if (errMsgContains(err, `a requested file or directory could not be found`)) {
    return true;
  }

  // Firefox and Safari
  if (errNameIs(err, `NotFoundError`)) {
    return true;
  }

  return false;
}

function errNameIs(err: any, name: string): boolean {
  if (!(`name` in err && typeof err.name === `string`)) {
    return false;
  }

  return err.name.toLowerCase() === name.toLowerCase();
}

function errMsgContains(err: any, substr: string): boolean {
  if (!(`message` in err && typeof err.message === `string`)) {
    return false;
  }

  return err.message.toLowerCase().includes(substr.toLowerCase());
}

export function getCacheFileName(namespace: string, key: any): string | null {
  if (key == null) {
    return null;
  }
  const stringified = JSON.stringify(key);

  // fnv.fast1a64 returns a hex string, which is always a valid file name.
  // See https://github.com/tjwebb/fnv-plus#fnvfast1a64string
  const hash = fnv.fast1a64(`${namespace}--${stringified}`);

  return `${hash}.json`;
}

async function getOPFSFileTextContent(fileName: string): Promise<string> {
  if (!(await opfsEnabled())) {
    console.error(
      'getOPFSFileTextContent should be disabled for user, but still called. Allowing request to succeed',
      window.viewer?.username
    );
  }
  const root = await navigator.storage.getDirectory();
  const fileHandle = await root.getFileHandle(fileName);
  const file = await fileHandle.getFile();
  const text = await file.text();
  return text;
}

async function storeTextFileInOPFS(
  fileName: string,
  textContent: string
): Promise<void> {
  if (!(await opfsEnabled())) {
    console.error(
      'storeTextFileInOPFS should be disabled for user, but still called',
      window.viewer?.username
    );
    return;
  }
  const root = await navigator.storage.getDirectory();
  const draftHandle = await root.getFileHandle(fileName, {create: true});

  // For some reason, TS in CI doesn't think `FileSystemFileHandle.createWritable` exists
  // Let's just cast for now
  const writable = await (draftHandle as any).createWritable();

  await writable.write(textContent);
  await writable.close();
}

// I may just be being dumb, but I couldn't find a way to clear the OPFS store in my browser.
// This is just here as a convenience when a full wipe is needed.
async function clearOPFS(): Promise<void> {
  try {
    const root = await navigator.storage.getDirectory();
    const promises = [];
    for await (const [name] of root as any) {
      console.log(`Removing ${name} from OPFS...`);
      promises.push(root.removeEntry(name));
    }
    await Promise.all(promises);
    console.log(`Removed ${promises.length} files from OPFS`);
  } catch (err) {
    logHandledError(`Error clearing OPFS cache - ${err}`);
  }
}
(window as any).clearOPFS = clearOPFS;
