// @ts-nocheck
/* tslint:disable */

import {safeLocalStorage} from './localStorage';

export function generateUnid(
  a // placeholder
) {
  return a // if the placeholder was passed, return
    ? // a random number from 0 to 15
      (
        a ^ // unless b is 8,
        ((Math.random() * // in which case
          16) >> // a random number from
          (a / 4))
      ) // 8 to 11
        .toString(16) // in hexadecimal
    : // or otherwise a concatenated string:
      ([1e10] + 1e10 + 1e9)
        .replace(
          // replacing
          /[01]/g, // zeroes and ones with
          generateUnid // random hex digits
        )
        .toUpperCase();
}

export default function startTrace() {
  const uuid = generateUnid();
  if (window.performance && window.performance.clearMarks) {
    window.performance.clearMarks();
    window.performance.clearMeasures();
    window.performance.mark(uuid + '-start');
  }
  safeLocalStorage.setItem('request_count', 0);
  safeLocalStorage.setItem('page_id', uuid);
}
