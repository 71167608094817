import {assertUnreachable} from '@wandb/weave/common/util/types';
import {getType} from 'typesafe-actions';

import {URL_STATE_KEYS, urlStateHasKey} from '../../services/url';
import * as Redux from '../../types/redux';
import * as ViewerTypes from '../viewer/types';
import {
  getNamedWorkspaceIdForView,
  getWorkspaceType,
} from '../workspaces/names';
import {
  namedWorkspaceBelongsToUser,
  NamedWorkspaceId,
  WorkspaceTypes,
} from '../workspaces/types';
import * as Actions from './actions';
import {
  deleteHistoryForObject,
  deleteObject,
  updateViewName,
  updateViewSpec,
} from './actionsInternal';
import {LoadableView} from './types';

function isAlwaysSavedAction(a: Redux.RootAction): boolean {
  if (
    a.type === getType(Actions.save) ||
    a.type === getType(Actions.setLocked)
  ) {
    return true;
  }
  return false;
}

export const getViewBehavior = (
  view: LoadableView,
  nwId?: NamedWorkspaceId
): ViewBehavior | null => {
  switch (view.type) {
    case 'group-view':
    case 'project-view':
    case 'run-view':
    case 'sweep-view':
      return behaviorForWorkspace(nwId);
    case 'runs':
    case 'runs/draft':
      return reportBehavior;
    default:
      assertUnreachable(view.type);
  }
};

const behaviorForWorkspace = (nwId?: NamedWorkspaceId) => {
  // If redux doesn't have a nwId specified, then NW 1.2 toggle is not on
  if (nwId == null) {
    return workspaceBehavior;
  }

  return namedWorkspaceBehavior;
};

export type ViewSaveAction = 'skip' | 'mark-modified' | 'save';

export type ViewBehavior = {
  determineSaveBehavior(
    action: Redux.RootAction,
    view: LoadableView,
    viewer?: ViewerTypes.Viewer
  ): ViewSaveAction;
};

const NW_ALLOWED_ACTIONS: string[] = [
  deleteObject,
  deleteHistoryForObject,
  updateViewName,
  updateViewSpec,
  Actions.rename,
].map(getType);
export const namedWorkspaceBehavior: ViewBehavior = {
  determineSaveBehavior(
    action: Redux.RootAction,
    view: LoadableView,
    viewer?: ViewerTypes.Viewer
  ) {
    // folks who aren't logged in can never save
    if (viewer == null) {
      return 'skip';
    }

    // If we are explicitly saving, it means the business logic elsewhere has decided
    // that it is okay to save the view, always let that happen even if they are
    // not the user.
    if (action.type === '@view/save') {
      return 'save';
    }

    const nwId = getNamedWorkspaceIdForView(view.name, view.user);

    if (nwId.id.startsWith('xnw')) {
      return 'skip';
    }

    if (
      nwId.nwType === 'shared workspace' &&
      NW_ALLOWED_ACTIONS.some(item => action.type === item)
    ) {
      return 'save';
    }

    if (nwId.nwType === 'personal workspace') {
      return namedWorkspaceBelongsToUser(nwId, viewer)
        ? 'save'
        : 'mark-modified';
    }

    // As of the writing of this comment, marking a named workspaces as modified doesn't do anything,
    // but may be used in the future
    return 'mark-modified';
  },
};

export const workspaceBehavior: ViewBehavior = {
  determineSaveBehavior(
    action: Redux.RootAction,
    view: LoadableView,
    viewer?: ViewerTypes.Viewer
  ): 'skip' | 'mark-modified' | 'save' {
    const viewerIsOwner =
      viewer != null &&
      viewer.id === view.user.id &&
      getWorkspaceType(view.name) === WorkspaceTypes.user;

    const autoSaveDisabled = !view.autoSave && !isAlwaysSavedAction(action);
    if (view.project?.readOnly || !viewerIsOwner || autoSaveDisabled) {
      return 'mark-modified';
    }

    if (action.type === getType(Actions.setAutosave) && !view.modified) {
      // If this is the autoSave action itself, don't save if the view is not
      // modified. We currently use an action to mark reports for autosave
      // every time the edit report page is loaded. This prevents us from
      // triggering a save on page load.
      return 'skip';
    }

    // any presence of the `temporaryView` flag in the URL prevents autosaving
    // this simplifies things vs handling:
    // -`?temporaryView=lk2j3423`,
    // - `?temporaryView`,
    // - `?temporaryView=false`,
    // - etc...
    if (urlStateHasKey(URL_STATE_KEYS.TEMPORARY_VIEW)) {
      return 'skip';
    }

    return 'save';
  },
} as const;

export const reportBehavior: ViewBehavior = {
  determineSaveBehavior(
    action: Redux.RootAction,
    view: LoadableView,
    viewer?: ViewerTypes.Viewer
  ): 'skip' | 'mark-modified' | 'save' {
    const viewerIsOwner = viewer != null && viewer.id === view.user.id;

    const autoSaveDisabled = !view.autoSave && !isAlwaysSavedAction(action);
    if (view.project?.readOnly || !viewerIsOwner || autoSaveDisabled) {
      // don't save changes, just mark as modified
      return 'mark-modified';
    }

    if (action.type === getType(Actions.setAutosave) && !view.modified) {
      // If this is the autoSave action itself, don't save if the view is not
      // modified. We currently use an action to mark reports for autosave
      // every time the edit report page is loaded. This prevents us from
      // triggering a save on page load.
      return 'skip';
    }

    return 'save';
  },
} as const;
