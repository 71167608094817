import {Icon} from '@wandb/weave/components/Icon';
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import {useBetaFeatureNightModeEnabled} from '../..//util/useBetaFeature';
import {useNavUpdaterContext} from '../../components/NavContextProvider';
import {useViewer} from '../../state/viewer/hooks';
import * as urls from '../../util/urls';
import * as S from './NoAccessPage.styles';

// Hides breadcrumbs while on page
function useHideNavBreadcrumbs(): void {
  const {setShouldShowBreadcrumbs} = useNavUpdaterContext();
  useEffect(() => {
    setShouldShowBreadcrumbs(false);
    // clean up so that after a user navigates away from the page the breadcrumbs reappear
    return () => setShouldShowBreadcrumbs(true);
  }, [setShouldShowBreadcrumbs]);
}

const content = {
  loggedIn: {
    caption:
      'If this is the right URL ask the page owner to change the privacy permissions.',
    url: urls.homePage(),
    cta: 'Go back to home',
  },
  loggedOut: {
    caption:
      'Try logging in or ask the page owner to change the privacy permissions.',
    url: '/wandb/intro/reports/A-Few-of-Our-Favorite-W-B-Reports--VmlldzozMTAzNjQ3',
    cta: 'See our favorite W&B Reports',
  },
};

export const NoAccessPage = () => {
  useHideNavBreadcrumbs();
  const viewer = useViewer();
  const isNightMode = useBetaFeatureNightModeEnabled();
  const isLoggedIn = !!viewer;
  return (
    <S.Container data-test="no-access">
      <S.LockedIcon>
        <Icon name="lock-closed" width={24} height={24} />
      </S.LockedIcon>
      <S.Header>
        It looks like you’ve landed on a locked or empty page.
      </S.Header>
      <S.Caption>
        {isLoggedIn ? content.loggedIn.caption : content.loggedOut.caption}
      </S.Caption>
      <Link to={isLoggedIn ? content.loggedIn.url : content.loggedOut.url}>
        {/* Update to use button component when its created */}
        <S.Button isNightMode={isNightMode}>
          {isLoggedIn ? content.loggedIn.cta : content.loggedOut.cta}
          <Icon name="chevron-next" width={16} height={16} />
        </S.Button>
      </Link>
    </S.Container>
  );
};
