// eslint-disable-next-line import/no-cycle -- please fix if you can
import {getWorkspaceSettings} from '../../features/workspaceSettings/store';
import {useIsInLoadedReportContext} from '../../pages/ReportPage/LoadedReportContext';
import {
  useRampFlagDefaultGorillaOutliers,
  useRampFlagGorillaOutliersAvailable,
} from '../../util/rampFeatureFlags';
import {
  POINT_VISUALIZATION_OPTIONS,
  PointVisualizationOptions,
} from '../WorkspaceSettingsModal/controls/PointVisualizationTypes';

export const pointVisualizationIsBucketing = (p: PointVisualizationOptions) => {
  return p === POINT_VISUALIZATION_OPTIONS.BucketingGorilla;
};

export const usePointVisualizationMethod = () => {
  const pointVisualizationMethod = getWorkspaceSettings(
    s => s.pointVisualizationMethod
  );
  const hasRunOutliersAccess = useRampFlagGorillaOutliersAvailable();
  const isOutliersDefaultEligible = useRampFlagDefaultGorillaOutliers();
  const isInReport = useIsInLoadedReportContext();

  // users w/out access to run outliers will always be in sampling mode
  if (!hasRunOutliersAccess) {
    return POINT_VISUALIZATION_OPTIONS.Sampling;
  }

  // bucketing is not available in reports
  if (isInReport) {
    return POINT_VISUALIZATION_OPTIONS.Sampling;
  }

  /**
   * users who are enrolled to default to bucketing mode will be defaulted IF
   * they have yet to select a preferred point visualization method for the workspace
   */
  if (
    isOutliersDefaultEligible &&
    pointVisualizationMethod === POINT_VISUALIZATION_OPTIONS.SamplingByDefault
  ) {
    return POINT_VISUALIZATION_OPTIONS.BucketingGorilla;
  }

  // Users who haven't specificed a preference for the workspace will be defaulted to sampling
  if (
    pointVisualizationMethod === POINT_VISUALIZATION_OPTIONS.SamplingByDefault
  ) {
    return POINT_VISUALIZATION_OPTIONS.Sampling;
  }

  // sometimes you gotta just give the people what they want
  return pointVisualizationMethod ?? POINT_VISUALIZATION_OPTIONS.Sampling;
};
