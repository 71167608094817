import React from 'react';

import * as S from './HomePageSidebar.styles';

type SidebarSectionHeaderProps = {
  headerText: string;
};

export const SidebarSectionHeader = ({
  headerText,
}: SidebarSectionHeaderProps) => {
  return (
    <S.SidebarListSubHeader>
      <S.SidebarListSubHeaderText disableTypography>
        {headerText}
      </S.SidebarListSubHeaderText>
    </S.SidebarListSubHeader>
  );
};
