import {TargetBlank} from '@wandb/weave/common/util/links';
import React, {FC, Fragment} from 'react';

import {apolloClient as client} from '../../../apolloClient';
import {envIsPublicCloud} from '../../../config';
import {
  CachedEntityStorageInfoQuery,
  OrganizationStorageInfoQuery,
} from '../../../generated/graphql';
import {CACHED_ENTITY_STORAGE_INFO_QUERY} from '../../../graphql/entityStorage';
import {ORGANIZATION_STORAGE_INFO} from '../../../graphql/organizations';
import {Viewer} from '../../../state/viewer/types';
import {
  getAccountPrivileges,
  getStorageSub,
} from '../../../util/accounts/pricing';
import {bytesInGB} from '../../../util/storage';
import {
  accountSettingsBillingTab,
  accountSettingsUsagePage,
} from '../../../util/urls';
import {Account, AccountType} from '../../Search/SearchNav/types';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {ViewerOrg} from '..';
import {
  ReachingResourceLimitWarningBannerProps,
  ResourceSubscriptionHasBeenUpgradedBannerProps,
  ResourceSubscriptionLimitExceededWarningBannerProps,
} from '.';
export type StorageBannerFlags = {
  reachingStorageLimitWarningBannerProps?: ReachingResourceLimitWarningBannerProps;
  reachingArtifactStorageLimitWarningBannerProps?: ReachingResourceLimitWarningBannerProps;
  storageSubscriptionLimitExceededWarningBannerProps?: ResourceSubscriptionLimitExceededWarningBannerProps;
  artifactStorageSubscriptionLimitExceededWarningBannerProps?: ResourceSubscriptionLimitExceededWarningBannerProps;
  storageSubscriptionHasBeenUpgradedBannerProps?: ResourceSubscriptionHasBeenUpgradedBannerProps;
  artifactStorageSubscriptionHasBeenUpgradedBannerProps?: ResourceSubscriptionHasBeenUpgradedBannerProps;
};

type RawEntityStorageInfo = CachedEntityStorageInfoQuery['entity'];

type EntityStorageInfo = RawEntityStorageInfo & {
  storageBytes: number;
};

function isEntityStorageInfo(
  entity: RawEntityStorageInfo
): entity is EntityStorageInfo {
  return entity != null && typeof entity.storageBytes === 'number';
}

async function getStorageEntityList(
  account: Account
): Promise<EntityStorageInfo[]> {
  if (account.accountType === AccountType.Personal) {
    const response = await client.query<CachedEntityStorageInfoQuery>({
      query: CACHED_ENTITY_STORAGE_INFO_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        entityName: account.name,
      },
    });
    return [response.data.entity].filter(isEntityStorageInfo);
  }
  try {
    const response = await client.query<OrganizationStorageInfoQuery>({
      query: ORGANIZATION_STORAGE_INFO,
      fetchPolicy: 'network-only',
      variables: {
        organizationID: account.id,
      },
    });

    return response.data.organization?.teams.filter(isEntityStorageInfo) ?? [];
  } catch (error) {
    console.error('There was an error fetching the data: ', error);
    return [];
  }
}

export function useStorageBannerFlags(
  account: Account,
  viewer?: Viewer,
  viewerOrg?: ViewerOrg
): Promise<StorageBannerFlags> {
  return getStorageBannerFlags(account, viewer, viewerOrg);
}

export async function getStorageBannerFlags(
  account: Account,
  viewer?: Viewer,
  viewerOrg?: ViewerOrg
): Promise<StorageBannerFlags> {
  const flags: StorageBannerFlags = {};

  if (!envIsPublicCloud) {
    return flags;
  }

  if (account.isEnterprise) {
    return flags;
  }

  if (viewer == null) {
    return flags;
  }
  const storageEntities = await getStorageEntityList(account);

  const isBillingAdmin =
    account.accountType === AccountType.Personal ||
    viewerOrg?.billingUser?.username === viewer.username;

  const isOrgAdmin =
    account.accountType === AccountType.Personal ||
    (viewerOrg?.members.find(m => m.username === viewer.username)?.role ===
      'admin' ??
      false);

  const hasStorageSubscription = getStorageSub(viewerOrg) != null;

  const orgStorageBytes = storageEntities.reduce(
    (acc, team) => acc + team.storageBytes,
    0
  );

  const privileges = await getAccountPrivileges(account);
  const orgStorageBytesLimitGB = privileges.storageLimitGB;
  const orgStorageBytesLimit = privileges.storageLimitGB * bytesInGB;

  const accountBannerId = account.id ?? account.name;
  const billingAdminEmail = viewerOrg?.billingUser?.email ?? undefined;

  // 90% of bytes used banners
  if (
    orgStorageBytesLimit >= orgStorageBytes &&
    orgStorageBytes >= orgStorageBytesLimit * 0.9
  ) {
    flags.reachingStorageLimitWarningBannerProps = {
      organizationId: accountBannerId,
      organizationName: account.name,
      isOrgAdmin,
      isBillingAdmin,
      billingAdminEmail,
      hasStorageSubscription,
      orgStorageBytes,
      orgStorageBytesLimitGB,
    };
  }

  // >100% of bytes used with no subscription
  if (!hasStorageSubscription && orgStorageBytes > orgStorageBytesLimit) {
    flags.storageSubscriptionLimitExceededWarningBannerProps = {
      hasStorageSubscription,
      organizationId: accountBannerId,
      organizationName: account.name,
      isOrgAdmin,
      isBillingAdmin,
      billingAdminEmail,
      orgStorageBytesLimitGB,
    };
  }

  // >100% of bytes used with existing subscription
  if (hasStorageSubscription && orgStorageBytes > orgStorageBytesLimit) {
    flags.storageSubscriptionHasBeenUpgradedBannerProps = {
      organizationId: accountBannerId,
      organizationName: account.name,
      resource: 'storage',
      orgStorageBytesLimitGB,
    };
  }

  return flags;
}

export const HasStorageSubscriptionSection: FC<{
  organizationName: string;
}> = ({organizationName}) => (
  <>
    Your plan will switch to pay-as-you-go to accommodate storage.{' '}
    <TargetBlank
      href={accountSettingsUsagePage(organizationName)} // We don't have to worry about the old usage page since enterprise plans on server have no storage limit so don't need to free up space
      className="font-semibold underline">
      Manage your usage
    </TargetBlank>{' '}
    to stay under the free storage limit.
  </>
);

const FreeUpSpaceLink: FC<{
  organizationName: string;
}> = ({organizationName}) => (
  <TargetBlank
    href={accountSettingsUsagePage(organizationName)} // We don't have to worry about the old usage page since enterprise plans on server have no storage limit so don't need to free up space
    className="font-semibold underline">
    free up space
  </TargetBlank>
);
const BuyMoreStorageLink: FC<{
  organizationName: string;
}> = ({organizationName}) => (
  <TargetBlank
    className="font-semibold underline"
    href={`${accountSettingsBillingTab(
      organizationName
    )}?openBuyStorageModal=true`}>
    buy more storage
  </TargetBlank>
);

export const StorageUsageBannerContent: FC<{
  organizationName: string;
  isBillingAdmin: boolean;
  isOrgAdmin: boolean;
  billingAdminEmail?: string;
  statusMsg: string;
}> = ({
  organizationName,
  isBillingAdmin,
  isOrgAdmin,
  billingAdminEmail,
  statusMsg,
}) => {
  const billingAdminReference = billingAdminEmail ?? 'your billing admin';
  const getStorageMsg = isBillingAdmin ? (
    <BuyMoreStorageLink organizationName={organizationName} />
  ) : (
    <>contact {billingAdminReference} to buy more storage</>
  );
  const freeSpaceMsg = <FreeUpSpaceLink organizationName={organizationName} />;

  return (
    <>
      {isOrgAdmin ? (
        <>
          {statusMsg} until you {freeSpaceMsg} or {getStorageMsg}.
        </>
      ) : (
        <>
          {statusMsg}. Ask an admin to free up space or {getStorageMsg}.
        </>
      )}
    </>
  );
};
