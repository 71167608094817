import React, {ReactNode, useEffect} from 'react';

import {Analytics} from '../../services/analytics';
import {EventData} from '../../services/analytics/types';
import {trackBannerButtonClicked} from '../../util/navigation';
import {
  BANNER_VARIANTS,
  BannerButtonWrapper,
  BannerVariant,
  BannerWrapper,
} from './Banner.styles';

type Props = {
  button?: ReactNode;
  children?: ReactNode;
  className?: string;
  eventData?: EventData;
  style?: React.CSSProperties;
  variant?: BannerVariant;
};

type ButtonProps = Pick<Props, 'variant'> &
  Required<Pick<Props, 'eventData'>> & {
    link?: string;
    text?: ReactNode;
  };

export const BannerButton = React.memo(
  ({
    eventData,
    link,
    text,
    variant = BANNER_VARIANTS.INFO,
    ...props
  }: ButtonProps) => (
    <BannerButtonWrapper
      variant={variant}
      onClick={() => {
        trackBannerButtonClicked(eventData);
        // this banner button link always expects internal wandb doc links
        // eslint-disable-next-line wandb/no-unprefixed-urls
        window.open(link);
      }}
      {...props}>
      {text}
    </BannerButtonWrapper>
  )
);
BannerButton.displayName = 'BannerButton';

export const Banner = React.memo(
  ({eventData, variant = BANNER_VARIANTS.INFO, ...props}: Props) => {
    useEffect(() => {
      if (eventData) {
        Analytics.track('Banner Viewed', eventData ?? {});
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <BannerWrapper variant={variant} {...props} />;
  }
);
Banner.displayName = 'Banner';
