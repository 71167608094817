import {ActionType as TSActionType, getType} from 'typesafe-actions';

import {
  APIAddedPanelSpecs,
  DefaultPanelSpecs,
} from '../../components/PanelBank/types';
import {getExpectedPanelSpecs} from '../../util/panelbank';
import * as Actions from './actions';

/**
 * Expected panels based on history keys and wandb config.
 * Updated whenever new keys or wbconfig data is received.
 * Used to compute panel bank diff and init/update panels.
 *
 * Ideally this would be part of the views reducer state,
 * but we had to pull it out because most views actions are
 * processed with immer, which has severe perf issues if the
 * views state gets too large. The `expectedPanels` state
 * may be very large if the user has logged many keys.
 */
export type StateType = {
  defaultSpecs: DefaultPanelSpecs;
  apiAddedSpecs: APIAddedPanelSpecs;
} | null;

export type ActionType = TSActionType<typeof Actions>;

export function expectedPanels(state: StateType = null, action: ActionType) {
  switch (action.type) {
    case getType(Actions.update): {
      const {keys, viz, wbConfig, autoOrganizePrefix} = action.payload;
      return getExpectedPanelSpecs(keys, viz, autoOrganizePrefix, wbConfig);
    }
    case getType(Actions.clear): {
      return null;
    }
    default: {
      return state;
    }
  }
}
