import * as globals from '@wandb/weave/common/css/globals.styles';
import {hexToRGB} from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const Container = styled.div`
  height: 506px;
  max-width: 1072px;
  margin: 32px auto;
  border-radius: 8px;
  background-color: ${globals.WHITE};
  padding: 0 32px;
  padding-top: 127px;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;

  gap: 24px;

  // Text Styles
  font-family: 'Source Sans Pro';
  font-style: normal;

  line-height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: ${globals.MOON_650};

  // Max width is 1072px + 2 32px margins
  @media only screen and (max-width: 1136px) {
    margin: 32px;
  }
`;

export const LockedIcon = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 60px;
  background-color: ${hexToRGB(globals.SIENNA_300, 0.48)};
  padding: 18px;
  color: ${globals.SIENNA_600};
`;

export const Header = styled.span`
  font-size: 30px;
`;

export const Caption = styled.span`
  font-weight: 400;
`;

export const Button = styled.div<{isNightMode: boolean}>`
  line-height: 24px;

  // hacky because night mode inverts colors, set to black, so the invert filter sets it to white
  color: ${({isNightMode}) =>
    isNightMode ? globals.OBLIVION : globals.MOON_800};

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 8px 12px;
  gap: 4px;

  // hacky because night mode inverts colors, set to black, so the invert filter sets it to white
  background-color: ${({isNightMode}) =>
    isNightMode ? globals.BLACK_TRANSPARENT : globals.GRAY_TRANSPARENT};

  border-radius: 4px;

  :hover {
    color: ${({isNightMode}) =>
      isNightMode ? globals.TEAL_500 : globals.TEAL_550};
    background-color: ${({isNightMode}) =>
      isNightMode
        ? hexToRGB(globals.TEAL_400, 0.48)
        : hexToRGB(globals.TEAL_300, 0.48)};
  }
`;
