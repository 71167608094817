import {ActionType, getType} from 'typesafe-actions';

import {immerReducer} from '../reducer';
import * as Actions from './actions';
import * as Types from './types';

function workspaces(draft: Types.StateType, action: Actions.ActionType) {
  switch (action.type) {
    case getType(Actions.initStart): {
      const {
        id,
        viewListID,
        viewType,
        defaultSpec,
        relatedId,
        entityName,
        projectName,
      } = action.payload;
      draft[id] = {
        viewListID,
        viewType,
        defaultSpec,
        relatedId,
        entityName,
        projectName,
        // we don't know what view to display during init, so these start
        // out as default values
        nwId: undefined,
        viewRef: undefined,
        error: undefined,
      };
      break;
    }
    case getType(Actions.unloadWorkspace): {
      const {id} = action.payload;
      delete draft[id];
      break;
    }
    case getType(Actions.viewLoadStart): {
      const {id} = action.payload;
      if (draft[id] != null) {
        draft[id].viewRef = undefined;
      }
      break;
    }
    case getType(Actions.showNewView):
      {
        const {workspaceId, nwId} = action.payload;
        const workspace = draft[workspaceId];
        if (nwId !== 'legacy' && workspace == null) {
          // log when we cannot find workspace when NW 1.2 is enabled
          console.error('showNewView - invalid workspace id', {
            workspaceId,
            workspaces: JSON.stringify(draft),
          });
          break;
        }

        if (action.payload.viewRef == null) {
          workspace.viewRef = undefined;
          // log to determine why we hit this case
          console.error('showNewView - invalid viewRef', {
            workspaceId,
            workspaces: JSON.stringify(draft),
            payload: action.payload,
          });
        } else {
          workspace.viewRef = action.payload.viewRef;
        }

        if (nwId === 'legacy') {
          workspace.nwId = undefined;
        } else {
          workspace.nwId = nwId;
        }
      }
      break;
    case getType(Actions.removeWorkspaceView): {
      const {workspaceId, cidToRemove} = action.payload;
      const workspace = draft[workspaceId];
      if (workspace == null) {
        return;
      }
      workspace.namedWorkspaceViewCids =
        workspace.namedWorkspaceViewCids?.filter(cid => cid !== cidToRemove);
      break;
    }
    case getType(Actions.newWorkspaceViews): {
      const {workspaceId} = action.payload;
      const workspace = draft[workspaceId];
      if (workspace == null) {
        // we are seeing this occur in prod, adding logging to determine the problem
        console.error('newWorkspaceViewAdded - invalid workspace id', {
          workspaceId,
          workspaces: JSON.stringify(draft),
        });
        break;
      }
      if (workspace.namedWorkspaceViewCids == null) {
        workspace.namedWorkspaceViewCids = action.payload.viewCids;
      } else {
        workspace.namedWorkspaceViewCids.push(...action.payload.viewCids);
      }
      break;
    }
    case getType(Actions.displayWorkspaceError): {
      const {workspaceId, error} = action.payload;
      const workspace = draft[workspaceId];
      console.error('Workspace error occurred', error);
      if (workspace == null) {
        // we are seeing this occur in prod, adding logging to determine the problem
        console.error('displayWorkspaceError - invalid workspace id', {
          workspaceId,
          workspaces: JSON.stringify(draft),
        });
        break;
      }
      workspace.error = error;
      break;
    }
  }
}

export default immerReducer<Types.StateType, ActionType<typeof Actions>>(
  workspaces,
  {}
);
