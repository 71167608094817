import {createAction} from 'typesafe-actions';

import {OrganizationPrefix} from '../../components/PanelBank/types';
import {RunHistoryKeyInfoKeys} from '../../types/run';
import {VizMap} from '../graphql/historyKeysQuery';
import {WandbConfig} from '../graphql/wandbConfigQuery';

export const update = createAction(
  '@expectedPanels/update',
  action =>
    (
      keys: RunHistoryKeyInfoKeys,
      viz: VizMap,
      wbConfig: WandbConfig,
      autoOrganizePrefix?: OrganizationPrefix
    ) =>
      action({keys, viz, wbConfig, autoOrganizePrefix})
);

export const clear = createAction('@expectedPanels/clear');
