import * as Sentry from '@sentry/react';
import {
  TrackedWandbLoader,
  TrackedWandbLoaderProps,
} from '@wandb/weave/common/components/WandbLoader';
import React, {useCallback} from 'react';
import {useLocation} from 'react-router';

import {useSelector} from './../../state/hooks';

export const InstrumentedLoader = (
  props: Omit<TrackedWandbLoaderProps, 'track'>
) => {
  const location = useLocation();
  const viewerData = useSelector(state => state.viewer.viewer);

  const addData = useCallback(
    () => ({
      entity: viewerData?.entity || null,
      location: location.pathname,
      organizations: viewerData?.organizations.map(org => org.id) || [],
      user: viewerData?.id || null,
    }),
    [location.pathname, viewerData]
  );
  return (
    <TrackedWandbLoader
      samplingRate={0.0025}
      {...props}
      captureException={Sentry.captureException}
      profilingCb={addData}
      // @ts-ignore
      track={window.analytics?.track}
    />
  );
};
