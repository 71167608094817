import gql from 'graphql-tag';
import {useMemo} from 'react';

import {TeamMemberRole} from '../../components/MembersTable/TeamMembersTable/utils';
import {Member, useTeamMembersQuery} from '../../generated/graphql';
import {Viewer} from '../viewer/types';

// Used for fetching potential report authors, checking team membership, and admin status
export const TEAM_MEMBERS_QUERY = gql`
  query TeamMembers($entityName: String!) {
    entity(name: $entityName) {
      id
      members {
        id
        username
        name
        photoUrl
        role
      }
    }
  }
`;

type TeamMemberArgs = {
  viewer?: Viewer;
  entityName?: string;
  skip?: boolean;
};
export type TeamMemberInfo = {
  viewerIsTeamAdmin: boolean;
  viewerIsTeamMember: boolean;
  foundTeamMember?: Member;
};
// Finds a team member -- hook should probably be renamed for clarity
export function useTeamMember({
  viewer,
  entityName,
  skip,
}: TeamMemberArgs): TeamMemberInfo {
  const teamInfoQuery = useTeamMembersQuery({
    variables: {
      entityName: entityName ?? '',
    },
    skip: entityName == null || skip,
  });

  return useMemo(() => {
    const members = teamInfoQuery.data?.entity?.members ?? [];
    return getTeamMemberInfo(viewer, members as Partial<Member[]>);
  }, [teamInfoQuery, viewer]);
}

export const findTeamMember = (
  viewer: Viewer | undefined,
  members: Partial<Member[]>
) => {
  return members.find(member => member?.id && member.id === viewer?.id);
};

export const getTeamMemberInfo = (
  viewer: Viewer | undefined,
  members: Partial<Member[]>
) => {
  const foundTeamMember = findTeamMember(viewer, members);

  if (foundTeamMember == null) {
    return {
      viewerIsTeamAdmin: false,
      viewerIsTeamMember: false,
    };
  }

  return {
    viewerIsTeamAdmin: foundTeamMember.role === TeamMemberRole.ADMIN,
    viewerIsTeamMember: true,
    foundTeamMember,
  };
};
