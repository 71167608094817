import {UserAccountType} from '../../../generated/graphql';
import {MemberData} from '../../../graphql/teamQuery';

export enum TeamMemberRole {
  ADMIN = 'admin',
  MEMBER = 'member',
  SERVICE = 'service',
  VIEW_ONLY = 'viewer',
}

export function getRoleLabel(member: MemberData) {
  if (member.memberRole == null) {
    return '';
  }
  if (member.accountType === UserAccountType.Service) {
    return 'service';
  }
  if (member.pending) {
    return 'invited';
  }
  return member.memberRole.name;
}
