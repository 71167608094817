import {Button} from '@wandb/weave/components';
import * as Dialog from '@wandb/weave/components/Dialog';
import {Icon} from '@wandb/weave/components/Icon';
import React, {memo, useCallback} from 'react';
import {toast} from 'react-toastify';

import {useRemoveUserFromOrganizationMutation} from '../../../generated/graphql';
import {
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../../../util/errors';
import * as urls from '../../../util/urls';
import * as S from './RemoveOrganizationMemberModal.styles';

type RemoveOrganizationMemberModalProps = {
  memberUsername: string;
  organizationID: string;
  organizationName: string;
  onRemoveMember: (() => Promise<any>) | (() => void);
  onClose: () => void;
};

const RemoveOrganizationMemberModalComp = ({
  memberUsername,
  organizationID,
  organizationName,
  onRemoveMember,
  onClose,
}: RemoveOrganizationMemberModalProps) => {
  const [removeUserFromOrganizationMutation] =
    useRemoveUserFromOrganizationMutation({
      context: propagateErrorsContext(),
    });

  const removeMember = useCallback(async () => {
    try {
      window.analytics?.track('Remove Organization Member Clicked', {
        organizationName,
        username: memberUsername,
        location: 'edit user modal',
      });
      const removeUserResult = await removeUserFromOrganizationMutation({
        variables: {
          userName: memberUsername,
          organizationId: organizationID,
        },
      });

      if (removeUserResult.errors) {
        const errMsg = removeUserResult.errors.map(e => e.message).join(', ');
        toast(`Error removing user: ${removeUserResult.errors}`);
        window.analytics?.track('Remove Organization Member Error Viewed', {
          organizationName,
          username: memberUsername,
          location: 'remove organization member modal',
          error: errMsg,
        });
      } else if (
        removeUserResult.data &&
        removeUserResult.data.removeUserFromOrganization?.success
      ) {
        await onRemoveMember();
        toast('Successfully removed user from organization.');
        window.analytics?.track('Organization Member Removed', {
          organizationName,
          username: memberUsername,
          location: 'remove organization member modal',
        });
        onClose();
      }
    } catch (err) {
      const errMsg = extractErrorMessageFromApolloError(err);
      toast(`Error removing user from organization: ${errMsg}`);
      window.analytics?.track('Remove Organization Member Error Viewed', {
        organizationName,
        username: memberUsername,

        location: 'remove organization member modal',
        error: errMsg,
      });
    }
  }, [
    onRemoveMember,
    onClose,
    memberUsername,
    removeUserFromOrganizationMutation,
    organizationID,
    organizationName,
  ]);

  return (
    <Dialog.Root
      open={true}
      onOpenChange={() => {
        onClose();
      }}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content
          className="w-[600px]"
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          <Dialog.Title>
            <S.AlignedSection>
              <Icon name="warning-alt" style={{marginRight: '10px'}} />
              Confirm Action
            </S.AlignedSection>
          </Dialog.Title>
          <Dialog.Description>
            <p>
              Are you sure you want to remove{' '}
              <S.Username href={urls.entity(memberUsername)}>
                {memberUsername}
              </S.Username>
              ? They will also be removed from teams they are currently a part
              of.
            </p>
          </Dialog.Description>
          <div className="mt-24 flex justify-end gap-8">
            <Dialog.Close>
              <Button
                onClick={() => {
                  removeMember();
                }}
                variant="destructive"
                size="medium">
                Confirm
              </Button>
            </Dialog.Close>
            <Dialog.Close>
              <Button
                onClick={() => {
                  onClose();
                }}
                variant="quiet"
                size="medium">
                Cancel
              </Button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export const RemoveOrganizationMemberModal = memo(
  RemoveOrganizationMemberModalComp
);
