import {WBIcon} from '@wandb/ui';
import * as globals from '@wandb/weave/common/css/globals.styles';
import {IconCheckmark} from '@wandb/weave/components/Icon';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectionItemContainer = styled.div<{
  $selected?: boolean;
  $disabled?: boolean;
}>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid ${p => (p.$selected ? globals.TEAL_350 : globals.MOON_50)};
  border-radius: 4px;
  background: ${globals.WHITE};
  padding: calc(${globals.spu} * 2);
  margin: 8px;
  cursor: ${props => (props.$disabled ? 'not-allowed' : 'pointer')};
`;

export const Checkmark = styled(IconCheckmark)<{selected?: boolean}>`
  color: ${p => (p.selected ? globals.TEAL_600 : globals.MOON_350)};
  min-width: 32px;
  min-height: 32px;
`;

export const IconAndTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.2px;
`;

export const ItemDescription = styled.div`
  font-size: ${globals.fontSizeStandard};
  line-height: 24px;
`;

export const ItemLabelAndDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const CheckMarkIcon = styled(WBIcon)<{$selected?: boolean}>`
  margin-left: calc(${globals.spu} * 2);
  color: ${p => (p.$selected ? globals.primary : globals.gray300)};
  font-size: 32px;
  font-weight: 800;
`;

export const IconContainer = styled.div<{$selected?: boolean}>`
  width: 64px !important;
  height: 64px !important;
  flex-shrink: 0;

  /* primary background, 8% opacity */
  background: ${p => (p.$selected ? globals.TEAL_300 : globals.MOON_100)};
  border-radius: 200px;

  /* Inside auto layout */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: calc(${globals.spu} * 2);
`;

export const Icon = styled(WBIcon)<{$selected?: boolean}>`
  font-size: 32px;
`;

export const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 3px 8px 5px;
  margin-left: 16px;

  font-size: 16px;
  font-weight: 400;

  /* gold-transparent */

  background: rgba(255, 201, 51, 0.2);
  border-radius: 3px;

  color: ${globals.GOLD_550};

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;
