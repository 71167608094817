import * as Sentry from '@sentry/react';

import {envIsProd} from './../../config';

/**
 *  High level notes on Sentry:
 * 1. Sentry is configured to capture console.errors and report them through the CaptureConsole integration
 * 2. We don't want to spam sentry with handled exceptions, but we do want them in the console for debugging while watching user sessions
 * 3. In a dev environment we want high visibility on errors (even handled ones?), so console.warn in prod, and console.error elsewhere
 * 4. these sentry functions return a string but IDK what we'd use it for. Just returning "" for now
 * 5. I don't have a good strategy for what captureContext should be, so just using Sentry's
 */

export const logHandledError: typeof Sentry.captureMessage = (
  msg,
  captureContext
) => {
  // it's handled by definition so we'll just warn
  console.warn(msg, captureContext);
  if (envIsProd) {
    return Sentry.captureMessage(msg, captureContext);
  }

  return '';
};

export const logError: typeof Sentry.captureException = (err, context) => {
  if (envIsProd) {
    console.warn(err, context); // warn in prod so we see it in the console messages
    return Sentry.captureException(err, context);
  }

  console.error(err, context); // error in dev
  return '';
};
