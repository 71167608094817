import {useEntityMemberRoleQuery} from '../generated/graphql';
import {useViewer} from '../state/viewer/hooks';
import {useAdminModeActive} from './admin';

export enum EntityPermissions {
  DeleteProject = 'project:delete',
  RenameProject = 'project:rename',
  UpdateProject = 'project:update',
}

export function useViewerHasPermissionForEntity(
  entityName: string,
  permission: string
) {
  const viewer = useViewer();
  const isAdminModeActive = useAdminModeActive();

  const {data, loading} = useEntityMemberRoleQuery({
    variables: {entityName, memberId: viewer?.id ?? ''},
    skip: !viewer || !entityName,
  });

  const role = data?.entity?.member?.memberRole;
  const inheritedPermissions = role?.inheritedFrom?.permissions ?? [];
  const permissions = inheritedPermissions.concat(role?.permissions ?? []);

  const hasPermission = permissions.some(p => p.name === permission) ?? false;

  if (!hasPermission && isAdminModeActive) {
    return {loading: false, hasPermission: true};
  }

  return {
    loading,
    hasPermission,
  };
}
