// THIS FILE MUST NOT IMPORT FROM ANY OTHER SOURCE FILE
// This lets us import globals from anywhere without introducing import cycles

export const FRAMEWORKS = Object.freeze([
  'keras',
  'tensorflow',
  'caffe',
  'theano',
  'torch',
  'scikit',
  'other',
]);
export const frameworkOptions = () => {
  return FRAMEWORKS.map(e => {
    return {
      text: e[0].toUpperCase() + e.slice(1),
      value: e,
    };
  });
};

export const PERMISSIONS = Object.freeze([]);

export const MAX_HISTORIES_LOADED = 10;
export const MAX_STORAGE_TREE_NODES = 500;
export const MAX_NUM_FILES = 10000;

export const CHART_SAMPLES = 1500;
// We must maintain CHART_SAMPLES / CHART_MAX_X_BUCKETS === 6
// to ensure each bucket has enough points to get a representative sample.
// Note: 6 is arbitrary, we may want more.
export const CHART_MAX_X_BUCKETS = 250;

export const NULL_STRING = 'null';
export const NULL_STRING_ANGLE_BRACKETS = '<null>';

export const TABLE_MAX_COLUMN_HEADER_LENGTH = 12; // unit = number of characters.  column names longer than this will display using truncateString
export const TABLE_DEFAULT_NAME_COLUMN_WIDTH = 280; // default px width for the "Name" column in the table
export const TABLE_DEFAULT_COLUMN_WIDTH = 90; // default px width for all other columns in the table
export const TABLE_MIN_COLUMN_WIDTH = 70; // minimum px width for columns in the table

export const PUBLISHED_PROJECT_NAME = '--internal-published-project';

export const NOOP = () => {}; // tslint:disable-line
export const RETURN_NULL = () => null;

export const EMPTY_ARRAY: readonly any[] = Object.freeze([]);
export const EMPTY_OBJECT = Object.freeze({});

export const RESERVED_KEYS = ['_wandb'];
