// eslint-disable-next-line import/no-cycle -- please fix if you can
import {MatchingTeamType} from './JoinMatchingOrgTeams';

type MatchingTeamTypeWithState = {
  team: MatchingTeamType;
  isSelected: boolean;
  isDisabled: boolean;
  isAlreadyMember: boolean;
};

export function annotateTeamsWithState(
  matchingTeams: MatchingTeamType[],
  selectedTeams: MatchingTeamType[],
  successfullyJoinedTeamIDs: Set<string>,
  teamsPendingAdminApproval: Set<string>,
  userTeamIDs: Set<string>,
  requestsInProgress: Set<string>
): MatchingTeamTypeWithState[] {
  return matchingTeams.map(team => {
    const isSelected =
      selectedTeams.some(({id}) => id === team.id) ||
      successfullyJoinedTeamIDs.has(team.id) ||
      teamsPendingAdminApproval.has(team.id) ||
      userTeamIDs.has(team.id);
    const isDisabled =
      successfullyJoinedTeamIDs.has(team.id) ||
      requestsInProgress.has(team.id) ||
      teamsPendingAdminApproval.has(team.id) ||
      userTeamIDs.has(team.id);
    const isAlreadyMember =
      userTeamIDs.has(team.id) || successfullyJoinedTeamIDs.has(team.id);
    return {team, isSelected, isDisabled, isAlreadyMember};
  });
}
