import React, {FC, memo, useCallback, useState} from 'react';

import * as S from './ChangeBillingUserModal.styles';

export interface ChangeBillingUserModalProps {
  newBillingUser: string;
  onConfirm(): void;
  onClose(): void;
}

// TODO: We should probably make a re-usable "AreYouSureModal" for this and the PurchaseSeatModal :/
const ChangeBillingUserModalComp: FC<ChangeBillingUserModalProps> = ({
  newBillingUser,
  onConfirm,
  onClose,
}) => {
  const [confirmClicked, setConfirmClicked] = useState(false);
  const handleConfirm = useCallback(() => {
    setConfirmClicked(true);
    onConfirm();
  }, [onConfirm]);
  return (
    <S.Modal open size="small" onClose={onClose}>
      <S.Header>Change billing user?</S.Header>
      <S.Content>
        {`${newBillingUser} will now receive all billing-related emails, will be made an organization admin, and will be able to see and update payment methods.`}
      </S.Content>
      <S.ButtonGroup>
        <S.Button size="small" onClick={onClose}>
          Cancel
        </S.Button>
        <S.Button
          disabled={confirmClicked}
          loading={confirmClicked}
          color="blue"
          size="small"
          data-test="confirm-purchase-seat"
          onClick={handleConfirm}>
          Change billing user
        </S.Button>
      </S.ButtonGroup>
    </S.Modal>
  );
};

export const ChangeBillingUserModal = memo(ChangeBillingUserModalComp);
