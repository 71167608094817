import {differenceInDays} from 'date-fns';
import * as React from 'react';
import {useContext} from 'react';

import {AccountSelectorContext} from '../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {AccountType} from '../../../components/Search/SearchNav/types';
import {useTrialCardAccountExpirationQuery} from '../../../generated/graphql';
import {PlanName} from '../../../pages/Billing/util';
import {UTM_Content, UTM_Source} from '../../../routes/paths';
import {Analytics} from '../../../services/analytics';
import {
  isCommunityEditionPrimaryPlan,
  isCommunityEditionPrimarySubscription,
  isTrialPlanPrimaryPlan,
  isTrialPlanPrimarySubscription,
  useGetSubscriptionPlanByName,
} from '../../../util/accounts/pricing';
import {navigateTo} from '../../../util/history';
import {useRampFlagAccountSelector} from '../../../util/rampFeatureFlags';
import {trialEndPage} from '../../../util/urls';
import {SidebarPlanCard} from './SidebarPlanCard';

export const TrialCard = () => {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const enableAccountSelector = useRampFlagAccountSelector();
  const {loading: loadingPlan, plans} = useGetSubscriptionPlanByName();
  const skip =
    selectedAccount == null ||
    selectedAccount.accountType === AccountType.Personal;
  const {loading: loadingExpiration, data} = useTrialCardAccountExpirationQuery(
    {
      variables: {organizationId: selectedAccount?.id ?? ''},
      skip,
    }
  );

  if (loadingPlan || loadingExpiration || !enableAccountSelector || skip) {
    return null;
  }

  // show for reverse trial or free plan only for now
  const orgSubscription = data?.organization?.subscriptions?.find(
    subscription =>
      isTrialPlanPrimaryPlan(subscription.plan) ||
      isCommunityEditionPrimaryPlan(subscription.plan)
  );

  const accountExpiration = orgSubscription?.expiresAt;

  let daysUntilExpired: number | null = null;
  if (accountExpiration != null) {
    daysUntilExpired = differenceInDays(
      new Date(accountExpiration),
      new Date()
    );
  }

  if (
    orgSubscription != null &&
    isTrialPlanPrimarySubscription(orgSubscription)
  ) {
    const downgradePlanName = PlanName.CommunityEdition;

    const downgradePlan = plans[downgradePlanName];

    const freePlanText = downgradePlan
      ? `${downgradePlan.displayName} plan`
      : 'free plan';

    return (
      <SidebarPlanCard
        title="Team's trial"
        description={`At the end of the trial, purchase a plan or downgrade to the ${freePlanText}`}
        daysUntilExpired={daysUntilExpired}
        onClick={() => {
          Analytics.track(`Choose Plan Clicked`, {
            location: 'Sidebar Trial Card',
            daysUntilExpired,
          });
          navigateTo({pathname: trialEndPage()});
        }}
        buttonLabel={
          daysUntilExpired == null || daysUntilExpired > 0
            ? 'Manage plan'
            : 'Choose plan'
        }
      />
    );
  }
  if (
    orgSubscription != null &&
    isCommunityEditionPrimarySubscription(orgSubscription)
  ) {
    const planName = plans[orgSubscription.plan.name as PlanName]?.displayName;
    return (
      <SidebarPlanCard
        title={`${planName} plan`}
        description="Upgrade for premium features or apply for an academic scholarship"
        daysUntilExpired={daysUntilExpired}
        onClick={() => {
          Analytics.track(`Choose Plan Clicked`, {
            location: 'Sidebar Trial Card',
            daysUntilExpired,
          });
          navigateTo({
            location: {
              pathname: trialEndPage(),
              search: `?utm_source=${UTM_Source.FREE_PLAN}&utm_content=${UTM_Content.LEFT_NAV}`,
            },
          });
        }}
        buttonLabel="View plans"
      />
    );
  }
  return null;
};
