import * as globals from '@wandb/weave/common/css/globals.styles';
import {TargetBlank} from '@wandb/weave/common/util/links';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const Username = styled(TargetBlank)`
  font-weight: 600;
  color: ${globals.TEAL_500};
  &:hover {
    color: ${globals.TEAL_600};
  }
`;

export const AlignedSection = styled.div`
  display: flex;
  align-items: center;
`;
