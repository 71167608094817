import moment from 'moment';

import {envIsPublicCloud} from '../../../config';
import {OrganizationMember} from '../../../generated/graphql';
import {getViewerOrganizationMemberRole} from '../../../pages/Billing/AccountSettings/util';
import {OrganizationMemberRole} from '../../../pages/Billing/EditOrgMembersModal';
import {PlanName} from '../../../pages/Billing/util';
import {Viewer} from '../../../state/viewer/types';
import {
  getPrimarySub,
  isExpiredSubscription,
  isNonAcademicOrg,
} from '../../../util/accounts/pricing';
import {getTotalTrackedHour} from '../../../util/trackedHour';
import {TrialSubscriptionExpiredBannerProps} from '../ExpirationEnforcementBanners/TrialSubscriptionExpiredBanner';
import {ViewerOrg} from '../GlobalBannerContextProvider';
import {orgFromViewerOrg} from '../utils';
import {TrackedHourLimitExceededWarningBannerProps} from './TrackedHourLimitExceededWarningBanner';

export const BASIC_PLAN_DEFAULT_TRACKED_HOUR_LIMIT = 250;

export type FreeAccountEnforcementBannerFlags = {
  trackedHourLimitExceededWarningBannerProps?: TrackedHourLimitExceededWarningBannerProps;
  trialSubscriptionExpiredBannerProps?: TrialSubscriptionExpiredBannerProps;
};

export async function getFreeAccountEnforcementBannerFlags(
  viewer?: Viewer,
  org?: ViewerOrg
): Promise<FreeAccountEnforcementBannerFlags> {
  const flags: FreeAccountEnforcementBannerFlags = {};

  if (org == null || viewer == null) {
    return flags;
  }

  if (!envIsPublicCloud) {
    return flags;
  }

  if (!isNonAcademicOrg(org)) {
    return flags;
  }

  const organization = orgFromViewerOrg(org);

  const primarySubscription = getPrimarySub(organization);
  const currentPrimaryPlanName = primarySubscription?.plan?.name;

  if (
    primarySubscription != null &&
    currentPrimaryPlanName === PlanName.TrialPlan &&
    isExpiredSubscription(primarySubscription)
  ) {
    flags.trialSubscriptionExpiredBannerProps = {
      organizationId: org.id,
    };
  }

  const orgTrackedLimitHours =
    primarySubscription?.privileges?.compute_hours ??
    BASIC_PLAN_DEFAULT_TRACKED_HOUR_LIMIT;
  const orgTrackedUsageDuration =
    getTotalTrackedHour(organization?.teams) ?? moment.duration(0);
  const isBasicPlan = currentPrimaryPlanName === PlanName.Basic;

  if (
    !isBasicPlan ||
    (orgTrackedUsageDuration?.asHours() ?? 0) < orgTrackedLimitHours
  ) {
    return flags;
  }

  const orgMembers: Array<
    Pick<OrganizationMember, 'id' | 'username' | 'role'>
  > = org?.members ?? [];

  const viewerMemberRole = getViewerOrganizationMemberRole(viewer, orgMembers);

  const viewerCanUpgrade =
    viewerMemberRole === OrganizationMemberRole.ADMIN ||
    viewerMemberRole === OrganizationMemberRole.BILLING_ONLY;

  flags.trackedHourLimitExceededWarningBannerProps = {
    organization: org,
    currentPlan: currentPrimaryPlanName,
    viewerCanUpgrade,
  };

  return flags;
}
